import React, { useEffect, useState } from "react";
import { Dispatch } from "redux";

import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../reducers";

import Loading from "../../components/loading";
import { IAlbum } from "../../interfaces/common";
import Album from "../../components/Album/album";
import { getAlbums } from "../../actions/albums";
import { Link, useParams } from "react-router-dom";
import { getUrl } from "../../libs/helpers";

const AlbumDetails = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { albums, isLoading } = useSelector((state: IState) => state.albums);

  const [album, setAlbum] = useState<IAlbum | null>(null);

  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    dispatch(getAlbums());
  }, [dispatch]);

  useEffect(() => {
    const pickedAlbum = albums.find((a: IAlbum) => a.slug === slug);
    if (!!pickedAlbum && !!slug) {
      setAlbum(pickedAlbum);
    }
  }, [slug, albums]);

  if (isLoading) {
    return <Loading />;
  }

  if (!album) {
    return null;
  }

  return (
    <>
      {album?.title && (
        <div className="breadcrumbs">
          <Link to={getUrl("Posts")}>Блог</Link> &gt;&gt;{" "}
          <Link to={getUrl("Discography")}>Дискографія</Link> &gt;&gt;{" "}
          <Link to={getUrl("AlbumDetails", { slug: album.slug })}>
            {album.title}
          </Link>
        </div>
      )}

      <Album album={album} />
      {!!album.description && (
        <div className="album-description">
          <div dangerouslySetInnerHTML={{ __html: album.description }} />
        </div>
      )}
    </>
  );
};

export default AlbumDetails;
