import { Reducer } from "redux";

import * as albumsActions from "../../reducers/albums/actions";
import { IAction } from "../../interfaces/action";
import { acceptChangedItemToList } from "../../libs/reducers";
import { IAlbum, ITrack } from "../../interfaces/common";
import { getTrackUrlByFalename } from "../../libs/helpers";

// export interface ITrackState {
//   tracks: ITrack[];
//   isLoading: boolean;
//   meta: {
//     total: number;
//     current: number;
//   };
// }

// export const trackInitialState: ITrackState = {
//   tracks: [],
//   isLoading: false,
//   meta: {
//     total: 0,
//     current: 1,
//   },
// };

export const albumsInitialState: IAlbumsState = {
  albums: [],
  isLoading: false,
  meta: {
    total: 0,
    current: 1,
  },
};

export interface IAlbumsState {
  albums: IAlbum[];
  isLoading: boolean;
  meta: {
    total: number;
    current: number;
  };
}

export const albumsReducers: Reducer = (
  state = albumsInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    // FETCH ALBUMS

    case albumsActions.fetchAlbumsAction.request: {
      return { ...state, albums: state.albums, isLoading: true };
    }
    case albumsActions.fetchAlbumsAction.success: {
      return {
        ...state,
        albums: payload.map((album: IAlbum) => {
          return {
            ...album,
            tracks: [
              ...album.tracks?.map((t: ITrack) => ({
                ...t,
                src: getTrackUrlByFalename(t.src),
              })),
            ],
          };
          // return album;
        }),
        isLoading: false,
      };
    }
    case albumsActions.fetchAlbumsAction.failure: {
      return { ...state, albums: state.albums, isLoading: false };
    }

    // FETCH ALBUM BY SLUG

    case albumsActions.fetchAlbumBySlugAction.request: {
      return { ...state, tracks: state.tracks, isLoading: true };
    }
    case albumsActions.fetchAlbumBySlugAction.success: {
      // return { ...state, tracks: acceptChangedItemToList(state.tracks, payload), isLoading: false };
      // const album = { ...payload, src: getTrackUrlByFalename(payload.src) };
      const album = payload;
      return {
        ...state,
        albums: acceptChangedItemToList(state.albums, album),
        isLoading: false,
      };
    }
    case albumsActions.fetchAlbumBySlugAction.failure: {
      return { ...state, tracks: state.tracks, isLoading: false };
    }

    default:
      return state;
  }
};
