import { useEffect } from "react";

export const useOutsideClick = (ref: any, isOpen: boolean, setOpen: any) => {
  useEffect(() => {
    const outsideClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    window.addEventListener("click", outsideClick);
    return () => {
      window.removeEventListener("click", outsideClick);
    };
  }, [ref, isOpen, setOpen]);
};
