// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Dashboard from "../dashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardLink,
  sendDashboardLink,
} from "../../../actions/dashboard";
import { IState } from "../../../reducers";
import { IAdminLink } from "../../../reducers/admin/reducer";

import {
  FacebookSvg,
  YouTubeSvg,
  InstagramSvg,
  GmailSvg,
  TelegramSvg,
  SpotifySvg,
} from "../../../components/Icons";

export const sourcesLinks: any = {
  Facebook: FacebookSvg,
  Instagram: InstagramSvg,
  Youtube: YouTubeSvg,
  Spotify: SpotifySvg,
  Gmail: GmailSvg,
  Telegram: TelegramSvg,
};

const DashboardLinkEdit: React.FC = () => {
  const [linkTitle, setLinkTitle] = useState("");
  const [linkIcon, setLinkIcon] = useState("");
  const [linkUrl, setLinkUrl] = useState("");

  const { linkId } = useParams<{ linkId: string }>();

  const link = useSelector((state: IState) => {
    return state.admin.links.links.find(
      (l: IAdminLink) => l.id === Number(linkId)
    );
  });

  const isLoading = useSelector((state: IState) => state.admin.links.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (/^([0-9]+)$/.test(String(linkId))) {
      dispatch(getDashboardLink(linkId));
    }
  }, [dispatch, linkId]);

  useEffect(() => {
    if (link && link.title) {
      setLinkTitle(link.title);
    }
    if (link && link.icon) {
      setLinkIcon(link.icon);
    }
    if (link && link.url) {
      setLinkUrl(link.url);
    }
  }, [link]);

  const onLinkSubmit = () => {
    const link = {
      title: linkTitle,
      icon: linkIcon,
      url: linkUrl,
    };
    dispatch(
      sendDashboardLink(
        /^([0-9]+)$/.test(linkId) ? { ...link, id: Number(linkId) } : link
      )
    );
  };

  const onSetLinkTitle = (e: any) => {
    setLinkTitle(e.target.value);
  };

  const onSetLinkIcon = (e: any) => {
    setLinkIcon(e.target.value);
  };

  const onSetLinkUrl = (e: any) => {
    setLinkUrl(e.target.value);
  };

  return (
    <Dashboard>
      <div className="dashboardLinkEdit">
        <h2>{linkId ? "Редагування посилання" : "Нове посилання"}</h2>
        <div className="titleField">
          <label htmlFor="title">Title:</label>
          <div className="input">
            <input
              type="text"
              name="title"
              value={linkTitle}
              onChange={onSetLinkTitle}
            />
          </div>
        </div>

        <div className="iconField">
          <label htmlFor="slug">Icon:</label>
          <select name="icon" value={linkIcon} onChange={onSetLinkIcon}>
            <option value="" label="Select a source" />
            {Object.keys(sourcesLinks).map((source: string) => (
              <option value={source} label={source} key={source} />
            ))}
          </select>
        </div>

        <div className="urlField">
          <label htmlFor="slug">Url (https://...):</label>
          <div className="input">
            <input
              type="text"
              name="icon"
              value={linkUrl}
              onChange={onSetLinkUrl}
            />
          </div>
        </div>

        <div className="submitBtn">
          <button onClick={onLinkSubmit} disabled={isLoading}>
            {isLoading ? "Loading.." : "Зберегти лінк"}
          </button>
        </div>
      </div>
    </Dashboard>
  );
};

export default DashboardLinkEdit;
