import moment from "moment";
import { useState } from "react";
// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";

import { Link } from "react-router-dom";
import { IPost } from "../../interfaces/common";
import { getImagePath, getImageThumbPath, getUrl } from "../../libs/helpers";
import { IGalleryItem, ITag } from "../../reducers/admin/reducer";
import MediaPlayer from "../Player/MediaPlayer";

import Lightbox from "react-spring-lightbox";
import { url } from "inspector";

type PostProps = {
  post?: IPost;
};

const Post = ({ post }: PostProps) => {
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShortViewOpen, setIsShortViewOpen] = useState<boolean>(false);

  if (!post) {
    return null;
  }

  const isShortView: boolean = post?.text?.length > 200;

  const images: string[] = [];

  if (post?.cover) {
    images.push(post.cover.filename);
  }

  if (post?.files && post.files.length > 0) {
    post.files.forEach((file: any) => {
      images.push(file.filename);
    });
  }

  const setIsShortViewOpenClick = () => {
    setIsShortViewOpen(!isShortViewOpen);
  };

  return (
    <>
      <Link className="title" to={getUrl("PostDetails", { slug: post.slug })}>
        <h3>{post.title}</h3>
      </Link>

      <div className="tagsAndData">
        <div className="tags">
          <div className="tags">
            {post?.tags &&
              post.tags.length > 0 &&
              post.tags.map((tag: ITag) => {
                return (
                  <Link
                    key={tag.slug}
                    to={{
                      pathname: getUrl("PostDetails", { slug: post.slug }),
                      search: `tags=${tag.slug}`,
                    }}
                  >
                    #{tag.title}
                  </Link>
                );
              })}
          </div>
        </div>
        <div className="publishedAt">
          {moment(post.publishedAt).format("LL")}
        </div>
      </div>

      {post?.text && (
        <div
          className={`info ${isShortView ? "shortview" : ""} ${
            isShortViewOpen ? "open" : ""
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: post.text }} />
          {isShortView && (
            <p onClick={setIsShortViewOpenClick} className="shortviewStub">
              {isShortViewOpen ? "Показати менше" : "Показати більше"}
            </p>
          )}
        </div>
      )}

      {post?.cover && (
        <div className="cover">
          <img
            src={getImagePath(post.cover.filename)}
            onClick={() => {
              setPhotoIndex(0);
              setIsOpen(true);
            }}
            alt={post.title}
          />
        </div>
      )}

      <div className="images">
        {!!post.files &&
          post.files.length > 0 &&
          post.files.map((file: IGalleryItem) => (
            <div
              key={file.filename}
              className="image"
              onClick={() => {
                setIsOpen(true);
                setPhotoIndex(
                  images.findIndex(
                    (filename: string) => filename === file.filename
                  )
                );
              }}
            >
              <img src={getImageThumbPath(file.filename)} alt={file.filename} />
            </div>
          ))}

        {/* {isOpen && (
          <Lightbox
            isOpen={isOpen}
            onPrev={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onNext={() => setPhotoIndex((photoIndex + 1) % images.length)}
            images={images.map((image) => ({
              src: getImagePath(image),
              alt: "",
            }))}
            currentIndex={photoIndex}
            className="cool-class"
            style={{ background: "rgba(0, 0, 0, .8)" }}
            onClose={() => setIsOpen(false)}
          />
        )} */}
      </div>

      <MediaPlayer post={post} />
    </>
  );
};

export default Post;
