import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { getTrackBySlug } from "../../actions/tracks";
import Loading from "../../components/loading";
import { IState } from "../../reducers";
import { Dispatch } from "redux";

import "./trackDetailsStyles.scss";
import { ITrack } from "../../interfaces/common";
import { DownloadSvg, PauseSvg, PlaySvg } from "../../components/Icons";
import { getUrl } from "../../libs/helpers";
import TrackDetailsHead from "./trackDetailsHead";
import {
  usePlayPause,
  usePlayTrack,
  usePlayerOptions,
} from "../../hooks/usePlayer";

const TrackDetails: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const track = useSelector((state: IState) =>
    state.tracks.tracks.find((p: ITrack) => p.slug === String(slug))
  );

  const {
    // track,
    playing,
    controls,
    light,
    volume,
    muted,
    loop,
    played,
    loaded,
    /*duration,*/ playbackRate,
    pip,
    seeking,
  } = useSelector((state: IState) => state.player);

  const setPlayPauseButton = usePlayPause();

  const handlePlayPauseButton = (track: ITrack) => {
    setPlayPauseButton(track);
  };

  const isLoading = useSelector((state: IState) => state.tracks.isLoading);
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (slug) {
      dispatch(getTrackBySlug({ slug }));
    }
  }, [dispatch, slug]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="trackDetails">
      {!!track && (
        <>
          {track?.title && (
            <div className="breadcrumbs">
              <Link to={getUrl("Posts")}>Блог</Link> &gt;&gt;{" "}
              <Link to={getUrl("Discography")}>Дискографія</Link> &gt;&gt;{" "}
              <Link to={getUrl("TrackDetails", { slug: track.slug })}>
                {track.title}
              </Link>
            </div>
          )}

          <TrackDetailsHead track={track} />

          <div className="trackOps">
            <div className="play" onClick={() => handlePlayPauseButton(track)}>
              {playing ? (
                <>
                  <PauseSvg /> <span>Pause</span>
                </>
              ) : (
                <>
                  <PlaySvg /> <span>Play</span>
                </>
              )}
            </div>

            {track?.src && (
              <a
                className="download"
                href={track?.src}
                target="_blank"
                rel="noreferrer"
              >
                <DownloadSvg width={20} height={20} />
                <span>Download</span>
              </a>
            )}
          </div>

          {track?.lyrics && (
            <div className="info">
              <div dangerouslySetInnerHTML={{ __html: track.lyrics }} />
            </div>
          )}

          {track?.description && (
            <div className="info">
              <div dangerouslySetInnerHTML={{ __html: track.description }} />
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default TrackDetails;
