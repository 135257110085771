import React from "react";
import "./stylesFooter.scss";
import { ILink } from "../../reducers/links/reducer";
import { sourcesLinks } from "../../pages/Dashboard/Links/linkEdit";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IState } from "../../reducers";

const Footer = () => {
  const { links } = useSelector((state: IState) => state.links);
  return (
    <footer className="footer">
      <div className="footer-inner">
        <ul className="social">
          {links.map((link: ILink) => {
            const IconSVG: any = sourcesLinks[link.icon];
            return (
              <li key={link.url}>
                <a href={link.url} target="_blank">
                  <IconSVG />
                </a>
              </li>
            );
          })}
        </ul>

        <div className="author">
          site created by{" "}
          <a rel="noopener noreferrer" href="https://titar.pro" target="_blank">
            titar
          </a>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
