import React from "react";

interface WrapperProps {
  children: React.ReactChild | React.ReactChild[];
  viewBox?: string;
  x?: string;
  y?: string;
  className?: string;
}

const Wrapper: React.FC<WrapperProps> = ({ children, ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest}>
    {children}
  </svg>
);

interface SvgProps {
  width?: number;
  height?: number;
  onClick?: Function;
}

export const PauseSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 200 200" className={`PauseSvg`} {...props}>
    <circle cx="100" cy="100" r="90" fill="none" strokeWidth={15} />
    <rect x="65" y="60" width="30" height="75" />
    <rect x="110" y="60" width="30" height="75" />
  </Wrapper>
);

export const PlaySvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 200 200" className={`PlaySvg`} {...props}>
    <circle cx="100" cy="100" r="90" fill="none" strokeWidth={15} />
    <polygon points="70, 55 70, 145 145, 100" />
  </Wrapper>
);

export const MuteOnSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 512 512" className={`MuteOnSvg`} {...props}>
    <path d="M268.5,121.2c-4.1-1.4-8.7-0.6-12.1,2.1l-97.9,77.6h-46.4c-12.3,0-22.2,9.9-22.2,22.2v65.9c0,12.3,9.9,22.2,22.2,22.2h46.4   l97.9,77.6c3.4,2.7,8,3.5,12.1,2.1c9.4-3.2,16.2-12.2,16.2-22.7V143.8C284.7,133.3,277.9,124.4,268.5,121.2z" />
    <path
      d="M 313,200 400,323"
      strokeWidth={30}
      fill="none"
      strokeLinecap={"round"}
    />
    <path
      d="M 313,323 400,200"
      strokeWidth={30}
      fill="none"
      strokeLinecap={"round"}
    />
  </Wrapper>
);

export const MuteOffSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 512 512" className={`MuteOffSvg`} {...props}>
    <path d="M268.5,121.2c-4.1-1.4-8.7-0.6-12.1,2.1l-97.9,77.6h-46.4c-12.3,0-22.2,9.9-22.2,22.2v65.9c0,12.3,9.9,22.2,22.2,22.2h46.4   l97.9,77.6c3.4,2.7,8,3.5,12.1,2.1c9.4-3.2,16.2-12.2,16.2-22.7V143.8C284.7,133.3,277.9,124.4,268.5,121.2z" />
    <path d="M313.4,323.5c-2.3,0-4.7-0.8-6.6-2.5c-4.1-3.6-4.5-10-0.9-14.1c12.4-14.1,19.2-32.1,19.2-50.9c0-18.7-6.8-36.8-19.2-50.9    c-3.6-4.1-3.2-10.5,0.9-14.1c4.1-3.6,10.5-3.2,14.1,0.9c15.6,17.7,24.2,40.5,24.2,64.1c0,23.6-8.6,46.4-24.2,64.1 C318.9,322.3,316.2,323.5,313.4,323.5z" />
    <path d="M344.5,346.2c-2.2,0-4.5-0.7-6.4-2.3c-4.3-3.5-4.9-9.8-1.3-14.1c16.9-20.5,26.2-46.5,26.2-73.1c0-27.3-9.7-53.7-27.3-74.4    c-3.6-4.2-3.1-10.5,1.1-14.1c4.2-3.6,10.5-3.1,14.1,1.1c20.7,24.3,32.1,55.4,32.1,87.4c0,31.3-10.9,61.8-30.8,85.9 C350.3,345,347.4,346.2,344.5,346.2z" />
    <path d="M374.6,370.7c-2.2,0-4.5-0.7-6.4-2.3c-4.3-3.5-4.9-9.8-1.3-14.1c22.7-27.5,35.2-62.3,35.2-98c0-36-12.7-71-35.7-98.6    c-3.5-4.2-3-10.5,1.3-14.1c4.2-3.5,10.5-3,14.1,1.3c26,31.2,40.3,70.7,40.3,111.4c0,40.3-14.1,79.6-39.7,110.7 C380.4,369.4,377.5,370.7,374.6,370.7z" />
  </Wrapper>
);

export const PlayNextSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 1000 1000" className={`PlayNextSvg`} {...props}>
    <path d="M702.2,500L81.1,10v980L702.2,500z M918.9,12H724.6v976.1h194.3V12z" />
  </Wrapper>
);

export const PlayPrevSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 1000 1000" className={`PlayPrevSvg`} {...props}>
    <path d="M297.8,500l621.1,490V10L297.8,500z M81.1,988h194.3V11.9H81.1V988z" />
  </Wrapper>
);

export const LoopOffSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 32 32" className={`LoopOffSvg`} {...props}>
    <path d="M 23 3 L 23 7 L 4 7 L 4 21.171875 L 6 19.171875 L 6 9 L 23 9 L 23 13 L 28 8 L 23 3 z M 28 10.828125 L 26 12.828125 L 26 23 L 9 23 L 9 19 L 4 24 L 9 29 L 9 25 L 28 25 L 28 10.828125 z M 16 13  z" />
  </Wrapper>
);

export const LoopOnSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 32 32" className={`LoopOnSvg`} {...props}>
    <path d="M 23 3 L 23 7 L 4 7 L 4 21.171875 L 6 19.171875 L 6 9 L 23 9 L 23 13 L 28 8 L 23 3 z M 28 10.828125 L 26 12.828125 L 26 23 L 9 23 L 9 19 L 4 24 L 9 29 L 9 25 L 28 25 L 28 10.828125 z M 16 13 C 16 14.116666 15.116666 15 14 15 L 14 17 C 14.730688 17 15.40837 16.786352 16 16.439453 L 16 20 L 18 20 L 18 13 L 16 13 z" />
  </Wrapper>
);

export const DragUpSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 26 26" className={`DragUpSvg`} {...props}>
    <path d="M 0 7 L 0 9 L 26 9 L 26 7 L 0 7 z M 0 12 L 0 14 L 26 14 L 26 12 L 0 12 z M 0 17 L 0 19 L 26 19 L 26 17 L 0 17 z M 9 22 L 13 26 L 17 22 L 9 22 z" />
  </Wrapper>
);

export const DragDownSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 26 26" className={`DragDownSvg`} {...props}>
    <path d="M 13 0 L 9 4 L 17 4 L 13 0 z M 0 7 L 0 9 L 26 9 L 26 7 L 0 7 z M 0 12 L 0 14 L 26 14 L 26 12 L 0 12 z M 0 17 L 0 19 L 26 19 L 26 17 L 0 17 z" />
  </Wrapper>
);

export const FacebookSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 409 409" x="0px" y="0px" {...props}>
    <path
      style={{ fill: "#475993" }}
      d="M353.701,0H55.087C24.665,0,0.002,24.662,0.002,55.085v298.616c0,30.423,24.662,55.085,55.085,55.085 	h147.275l0.251-146.078h-37.951c-4.932,0-8.935-3.988-8.954-8.92l-0.182-47.087c-0.019-4.959,3.996-8.989,8.955-8.989h37.882 	v-45.498c0-52.8,32.247-81.55,79.348-81.55h38.65c4.945,0,8.955,4.009,8.955,8.955v39.704c0,4.944-4.007,8.952-8.95,8.955 	l-23.719,0.011c-25.615,0-30.575,12.172-30.575,30.035v39.389h56.285c5.363,0,9.524,4.683,8.892,10.009l-5.581,47.087 	c-0.534,4.506-4.355,7.901-8.892,7.901h-50.453l-0.251,146.078h87.631c30.422,0,55.084-24.662,55.084-55.084V55.085 C408.786,24.662,384.124,0,353.701,0z"
    />
  </Wrapper>
);

export const InstagramSvg: React.FC<SvgProps> = (props) => {
  const rand = Math.random();
  return (
    <Wrapper viewBox="0 0 551 551" x="0px" y="0px" {...props}>
      <g>
        <linearGradient
          id={`SVGID_1_${rand}`}
          gradientUnits="userSpaceOnUse"
          x1="275.517"
          y1="4.57"
          x2="275.517"
          y2="549.72"
          gradientTransform="matrix(1 0 0 -1 0 554)"
        >
          <stop offset="0" style={{ stopColor: "#E09B3D" }} />
          <stop offset="0.3" style={{ stopColor: "#C74C4D" }} />
          <stop offset="0.6" style={{ stopColor: "#C21975" }} />
          <stop offset="1" style={{ stopColor: "#7024C4" }} />
        </linearGradient>

        <path
          style={{ fill: `url(#SVGID_1_${rand})` }}
          d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722 c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156 C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156 c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722 c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
        />

        <linearGradient
          id={`SVGID_2_${rand}`}
          gradientUnits="userSpaceOnUse"
          x1="275.517"
          y1="4.57"
          x2="275.517"
          y2="549.72"
          gradientTransform="matrix(1 0 0 -1 0 554)"
        >
          <stop offset="0" style={{ stopColor: "#E09B3D" }} />
          <stop offset="0.3" style={{ stopColor: "#C74C4D" }} />
          <stop offset="0.6" style={{ stopColor: "#C21975" }} />
          <stop offset="1" style={{ stopColor: "#7024C4" }} />
        </linearGradient>

        <path
          style={{ fill: `url(#SVGID_2_${rand})` }}
          d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517 S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6c-48.095,0-87.083-38.988-87.083-87.083 s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083C362.6,323.611,323.611,362.6,275.517,362.6z"
        />

        <linearGradient
          id={`SVGID_3_${rand}`}
          gradientUnits="userSpaceOnUse"
          x1="418.31"
          y1="4.57"
          x2="418.31"
          y2="549.72"
          gradientTransform="matrix(1 0 0 -1 0 554)"
        >
          <stop offset="0" style={{ stopColor: "#E09B3D" }} />
          <stop offset="0.3" style={{ stopColor: "#C74C4D" }} />
          <stop offset="0.6" style={{ stopColor: "#C21975" }} />
          <stop offset="1" style={{ stopColor: "#7024C4" }} />
        </linearGradient>

        <circle
          style={{ fill: `url(#SVGID_3_${rand})` }}
          cx="418.31"
          cy="134.07"
          r="34.15"
        />
      </g>
    </Wrapper>
  );
};

export const YouTubeSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 461.001 461.001" x="0px" y="0px" {...props}>
    <path
      style={{ fill: "#F61C0D" }}
      d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137	C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607	c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
    />
  </Wrapper>
);

export const SpotifySvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox='0 0 496 512' x='0px' y='0px' {...props}>
    <path
      fill='#1ed760'
      d='M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8Z'
    />
    <path d='M406.6 231.1c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3zm-31 76.2c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm-26.9 65.6c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4z' />
  </Wrapper>
);

export const DownloadSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 1000 1000" x="0px" y="0px" {...props}>
    <polygon points="534.8,755.6 534.8,145.4 465.2,145.4 465.2,755.6 292.3,582.7 243.2,631.9 500,888.7 756.8,631.9 707.7,582.7 " />
    <path d="M500,10C229.8,10,10,229.8,10,500s219.8,490,490,490c270.2,0,490-219.8,490-490S770.2,10,500,10z M500,923.7C266.4,923.7,76.3,733.6,76.3,500c0-233.6,190-423.7,423.7-423.7c233.6,0,423.7,190.1,423.7,423.7C923.7,733.6,733.6,923.7,500,923.7z" />
  </Wrapper>
);

export const LongRightArrowSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 500 1000" x="0px" y="0px" {...props}>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path
        style={{ fill: "#000" }}
        d="M1870 5003 c-39 -20 -60 -54 -60 -98 0 -21 192 -382 631 -1187 l632 -1158 -632 -1158 c-436 -800 -631 -1166 -631 -1187 0 -47 33 -92 79 -106 34 -10 44 -9 77 7 37 18 68 72 691 1214 486 891 652 1203 653 1228 0 26 -151 310 -652 1229 -363 665 -661 1201 -673 1209 -36 24 -77 26 -115 7z"
      />
    </g>
  </Wrapper>
);

export const LongLeftArrowSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 500 1000" x="0px" y="0px" {...props}>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path
        style={{ fill: "#000" }}
        d="M3136 4988 c-15 -13 -317 -556 -673 -1208 -506 -927 -647 -1193 -647-1220 0 -27 139 -290 643 -1215 365 -668 656 -1192 672 -1207 41 -39 100 -39 139 1 37 37 45 70 27 112 -8 19 -294 546 -635 1171 l-621 1138 632 1157 c694 1273 663 1207 599 1264 -41 37 -99 40 -136 7z"
      />
    </g>
  </Wrapper>
);

export const OrderArrowUpSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 -256 1792 1792" x="0px" y="0px" {...props}>
    <g transform="matrix(1,0,0,-1,53.152542,1262.6441)">
      <path d="m 1611,565 q 0,-51 -37,-90 l -75,-75 q -38,-38 -91,-38 -54,0 -90,38 L 1024,693 V -11 q 0,-52 -37.5,-84.5 Q 949,-128 896,-128 H 768 q -53,0 -90.5,32.5 Q 640,-63 640,-11 V 693 L 346,400 q -36,-38 -90,-38 -54,0 -90,38 l -75,75 q -38,38 -38,90 0,53 38,91 l 651,651 q 35,37 90,37 54,0 91,-37 l 651,-651 q 37,-39 37,-91 z" />
    </g>
  </Wrapper>
);

export const OrderArrowDownSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 -256 1792 1792" x="0px" y="0px" {...props}>
    <g transform="matrix(1,0,0,-1,68.338986,1315.7966)">
      <path d="m 1611,704 q 0,-53 -37,-90 L 923,-38 q -39,-37 -91,-37 -53,0 -90,37 L 91,614 q -38,36 -38,90 0,53 38,91 l 74,75 q 39,37 91,37 53,0 90,-37 L 640,576 v 704 q 0,52 38,90 38,38 90,38 h 128 q 52,0 90,-38 38,-38 38,-90 V 576 l 294,294 q 37,37 90,37 52,0 91,-37 l 75,-75 q 37,-39 37,-91 z" />
    </g>
  </Wrapper>
);

export const GmailSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 512 512" x="0px" y="0px" {...props}>
    <defs id="defs4">
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath3847">
        <rect
          height="401.85031"
          id="rect3849"
          ry="24.940269"
          style={{ fill: "#005cc8", fillOpacity: 1, stroke: "none" }}
          width="405.50885"
          x="192.91168"
          y="341.59683"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath4206">
        <rect
          height="342.67416"
          id="rect4208"
          ry="21.83189"
          style={{ fill: "#005ad2", fillOpacity: 1, stroke: "none" }}
          width="342.67416"
          x="275.56522"
          y="396.76877"
        />
      </clipPath>
      <clipPath clipPathUnits="userSpaceOnUse" id="clipPath3001">
        <rect
          height="363.35269"
          id="rect3003"
          rx="136.19997"
          ry="143.45973"
          style={{ fill: "#800080", fillOpacity: 1, stroke: "none" }}
          width="344.96527"
          x="271.56891"
          y="376.09024"
        />
      </clipPath>
    </defs>
    <g id="layer1" transform="translate(0,-540.36218)">
      <rect
        height="506.55707"
        id="rect3851"
        rx="200"
        ry="200"
        style={{ fill: "#db534b", fillOpacity: 1, stroke: "none" }}
        width="506.55707"
        x="3.1982057"
        y="544.98157"
      />
      <g
        clipPath="url(#clipPath3001)"
        id="g4145"
        style={{
          opacity: "0.48961247",
          fill: "#000000",
          fillOpacity: 0.05882353,
        }}
        transform="matrix(1.4684292,0,0,1.3850481,-395.58152,33.17362)"
      >
        <g
          id="g4147"
          style={{ fill: "#000000", fillOpacity: 0.05882353, stroke: "none" }}
        >
          <path
            d="m 561.53848,561.01605 c 0,61.06995 -50.79847,110.57692 -113.46155,110.57692 l 707.10677,707.10683 c 62.6631,0 113.4616,-49.507 113.4616,-110.577 z"
            id="path4149"
            style={{ fill: "#000000", fillOpacity: 0.05882353 }}
          />
          <path
            d="m 448.07693,671.59297 c -31.86024,0 -60.65337,-12.79797 -81.26213,-33.40672 l 707.1068,707.10675 c 20.6087,20.6088 49.4019,33.4068 81.2621,33.4068 z"
            id="path4151"
            style={{ fill: "#000000", fillOpacity: 0.05882353 }}
          />
          <path
            d="m 366.8148,638.18625 c -19.92477,-19.92478 -32.19941,-47.15048 -32.19941,-77.1702 l 707.10681,707.10675 c 0,30.0197 12.2746,57.2455 32.1994,77.1702 z"
            id="path4153"
            style={{ fill: "#000000", fillOpacity: 0.05882353 }}
          />
          <path
            d="m 334.61539,561.01605 c 0,-61.06994 50.79847,-110.57692 113.46154,-110.57692 l 707.10677,707.10677 c -62.6631,0 -113.4615,49.507 -113.4615,110.5769 z"
            id="path4155"
            style={{ fill: "#000000", fillOpacity: 0.05882353 }}
          />
          <path
            d="m 448.07693,450.43913 c 31.86024,0 60.65338,12.79797 81.26214,33.40672 l 707.10673,707.10675 c -20.6087,-20.6087 -49.4018,-33.4067 -81.2621,-33.4067 z"
            id="path4157"
            style={{ fill: "#000000", fillOpacity: 0.05882353 }}
          />
          <path
            d="m 529.33907,483.84585 c 19.92477,19.92478 32.19941,47.15049 32.19941,77.1702 l 707.10682,707.10675 c 0,-30.0197 -12.2747,-57.2454 -32.1995,-77.1702 z"
            id="path4159"
            style={{ fill: "#000000", fillOpacity: 0.05882353 }}
          />
        </g>
        <path
          d="m 561.53848,561.01605 c 0,61.06995 -50.79847,110.57692 -113.46155,110.57692 -62.66307,0 -113.46154,-49.50697 -113.46154,-110.57692 0,-61.06994 50.79847,-110.57692 113.46154,-110.57692 62.66308,0 113.46155,49.50698 113.46155,110.57692 z"
          id="path4111"
          style={{ fill: "#000000", fillOpacity: 0.05882353, stroke: "none" }}
        />
      </g>
      <path
        d="m 126.45878,662.80178 h 264.70977 a 33.088722,33.088722 0 0 1 33.08871,33.08872 v 198.5323 a 33.088722,33.088722 0 0 1 -33.08871,33.08875 H 126.45878 c -18.36423,0 -33.088718,-14.88991 -33.088718,-33.08875 V 695.8905 c 0,-18.36423 14.724488,-33.08872 33.088718,-33.08872 M 258.81367,778.6123 391.16855,695.8905 H 126.45878 l 132.35489,82.7218 M 126.45878,894.4228 H 391.16855 V 735.10064 L 258.81367,817.657 126.45878,735.10064 V 894.4228 z"
        id="path3864"
        style={{ fill: "#ffffff" }}
      />
    </g>
  </Wrapper>
);

export const TelegramSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 240 240" x="0px" y="0px" {...props}>
    <defs>
      <linearGradient id="a" x1=".667" x2=".417" y1=".167" y2=".75">
        <stop offset="0" stop-color="#37aee2" />
        <stop offset="1" stop-color="#1e96c8" />
      </linearGradient>
      <linearGradient id="b" x1=".66" x2=".851" y1=".437" y2=".802">
        <stop offset="0" stop-color="#eff7fc" />
        <stop offset="1" stop-color="#fff" />
      </linearGradient>
    </defs>
    <circle cx="120" cy="120" r="120" fill="url(#a)" />
    <path
      fill="#c8daea"
      d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"
    />
    <path
      fill="#a9c9dd"
      d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"
    />
    <path
      fill="url(#b)"
      d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"
    />
  </Wrapper>
);

export const TrashIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 27.965 27.965" x="0px" y="0px" {...props}>
    <g>
      <path
        fill="red"
        d="M13.98,0C6.259,0,0,6.261,0,13.983c0,7.721,6.259,13.982,13.98,13.982c7.725,0,13.985-6.262,13.985-13.982
			C27.965,6.261,21.705,0,13.98,0z M19.992,17.769l-2.227,2.224c0,0-3.523-3.78-3.786-3.78c-0.259,0-3.783,3.78-3.783,3.78
			l-2.228-2.224c0,0,3.784-3.472,3.784-3.781c0-0.314-3.784-3.787-3.784-3.787l2.228-2.229c0,0,3.553,3.782,3.783,3.782
			c0.232,0,3.786-3.782,3.786-3.782l2.227,2.229c0,0-3.785,3.523-3.785,3.787C16.207,14.239,19.992,17.769,19.992,17.769z"
      />
    </g>
  </Wrapper>
);

export const PublishedIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 512 512" x="0px" y="0px" {...props}>
    <g>
      <path
        fill="green"
        d="M150.5,208.1l-35.3,35.3l115.2,115.2L486,103.1l-35.3-35.3L230.4,286.2L150.5,208.1L150.5,208.1z M460,256.5
        c0,112.4-92,204.4-204.4,204.4s-204.4-92-204.4-204.4S143.1,52,255.5,52c19.5,0,38.1,2.8,56.7,7.4l40-40C322.4,6.5,290.8,0,256.5,0
        C116.2,0,0.9,115.2,0.9,255.5S115.2,512,255.5,512s255.5-115.2,255.5-255.5H460z"
      />
    </g>
  </Wrapper>
);

export const NotPublishedIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 512 512" x="0px" y="0px" {...props}>
    <g>
      <path
        fill="red"
        d="M204.8,325.3h68.5v68.5h-68.5V325.3z M273.3,120.5h-68.5v170.9h68.5V120.5z M324.5,171.7l-33.9,33.9
l85.1,85.1L512,137.1l-33.9-33.9L375.7,222.9L324.5,171.7z M238.7,451.3c-107.1,0-194.6-87.4-194.6-194.6S131.5,62.2,238.7,62.2
c62.2,0,117.4,29.9,153.6,74.8l31.5-31.5C380.5,52,313.5,17.3,238.7,17.3C107.1,17.3,0,124.5,0,256s107.1,238.7,238.7,238.7
s239.5-106.3,239.5-237.9l-52,52C403.3,390.7,328.5,451.3,238.7,451.3L238.7,451.3z"
      />
    </g>
  </Wrapper>
);

export const EyeOpenIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 121.025 121.025" x="0px" y="0px" {...props}>
    <g>
      <path
        fill="green"
        d="M1.35,64.212c7.9,9.9,31.4,36.3,59.2,36.3c27.8,0,51.3-26.399,59.2-36.3c1.699-2.2,1.699-5.3,0-7.399 c-7.9-9.9-31.4-36.3-59.2-36.3c-27.8-0.1-51.3,26.3-59.2,36.2C-0.45,58.913-0.45,62.012,1.35,64.212z M60.55,36.413	c13.3,0,24,10.7,24,24s-10.7,24-24,24c-13.3,0-24-10.7-24-24S47.25,36.413,60.55,36.413z"
      />
      <circle fill="green" cx="60.55" cy="60.413" r="12" />
    </g>
  </Wrapper>
);

export const EyeCloseIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 53.252 53.252" x="0px" y="0px" {...props}>
    <g>
      <path
        fill="red"
        d="M37.711,2.424C24.346-3.698,8.547,2.174,2.425,15.542c-6.123,13.366-0.249,29.166,13.117,35.287
			c13.365,6.121,29.165,0.25,35.287-13.117C56.95,24.344,51.078,8.545,37.711,2.424z M40.825,41.446
			c0,0.837-0.678,1.516-1.515,1.516H13.941c-0.836,0-1.515-0.679-1.515-1.516V25.166c0-0.837,0.678-1.515,1.515-1.515h2.819v-5.942
			c0-5.449,4.417-9.866,9.866-9.866c5.448,0,9.865,4.417,9.865,9.866v5.942h2.819c0.836,0,1.515,0.678,1.515,1.515V41.446z"
      />
      <path
        fill="red"
        d="M26.627,13.942c-3.443,0-6.234,3.682-6.234,3.682s2.792,3.682,6.234,3.682c3.441,0,6.233-3.682,6.233-3.682
			S30.068,13.942,26.627,13.942z M26.627,19.838c-1.176,0-2.13-0.953-2.13-2.13c0-1.177,0.954-2.13,2.13-2.13
			c1.175,0,2.129,0.953,2.129,2.13C28.756,18.885,27.801,19.838,26.627,19.838z"
      />
      <path
        fill="red"
        d="M28.898,30.358c0-1.255-1.017-2.271-2.271-2.271c-1.255,0-2.272,1.018-2.272,2.271c0,0.997,0.646,1.834,1.538,2.141 l-1.538,4.485h4.543l-1.538-4.485C28.252,32.191,28.898,31.355,28.898,30.358z"
      />
    </g>
  </Wrapper>
);

export const SetCoverImageIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 37 37" x="0px" y="0px" {...props}>
    <g>
      <path
        d="M7.733,9.61c0-1.448,1.174-2.622,2.622-2.622s2.622,1.174,2.622,2.622s-1.174,2.622-2.622,2.622
		S7.733,11.058,7.733,9.61z M36,0v26.413V37H1V0H36z M32,4H5v17.862l5.098-5.144l5.955,5.859L26.91,11.384l5.09,5.21V4z M32,22.318
		l-5.08-5.2L16.119,28.255l-5.986-5.891L5,27.544V33h27V22.318z"
      />
    </g>
  </Wrapper>
);

export const PlusIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 512 512" x="0px" y="0px" {...props}>
    <g>
      <path
        d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M384,277.333H277.333V384
			c0,11.776-9.557,21.333-21.333,21.333s-21.333-9.557-21.333-21.333V277.333H128.043c-11.776,0-21.333-9.557-21.333-21.333
			s9.557-21.333,21.333-21.333h106.624V128c0-11.776,9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333v106.667H384
			c11.776,0,21.333,9.557,21.333,21.333S395.776,277.333,384,277.333z"
      />
    </g>
  </Wrapper>
);

export const MinusIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 310 310" x="0px" y="0px" {...props}>
    <g>
      <path
        d="M155.143,0.001C69.597,0.001,0,69.597,0,155.143c0,85.545,69.597,155.142,155.143,155.142s155.143-69.597,155.143-155.142
	C310.285,69.597,240.689,0.001,155.143,0.001z M244.143,171.498c0,4.411-3.589,8-8,8h-163c-4.411,0-8-3.589-8-8v-32
	c0-4.411,3.589-8,8-8h163c4.411,0,8,3.589,8,8V171.498z"
      />
    </g>
  </Wrapper>
);

export const DropDownMenuIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 1000 1000" x="0px" y="0px" {...props}>
    <g>
      <path d="M517.9,264.1H77.7C40.3,264.1,10,286,10,313v24.5c0,27,30.3,48.9,67.7,48.9h440.2c37.4,0,67.7-21.9,67.7-48.9V313C585.6,286,555.3,264.1,517.9,264.1z M238.8,613.6H77.7c-37.4,0-67.7,21.9-67.7,48.9V687c0,27,30.3,48.9,67.7,48.9h161.1c37.4,0,67.7-21.9,67.7-48.9v-24.5C306.5,635.5,276.2,613.6,238.8,613.6z M378.3,438.9H77.7c-37.4,0-67.7,21.9-67.7,48.9v24.4c0,27,30.3,48.9,67.7,48.9h300.6c37.4,0,67.7-21.9,67.7-48.9v-24.4C446,460.8,415.7,438.9,378.3,438.9z" />
      <path d="M828.9,581.5L667.8,418.5H990L828.9,581.5z" />
    </g>
  </Wrapper>
);

export const DropUpMenuIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 1000 1000" x="0px" y="0px" {...props}>
    <g>
      <path d="M517.9,264.1H77.7C40.3,264.1,10,286,10,313v24.5c0,27,30.3,48.9,67.7,48.9h440.2c37.4,0,67.7-21.9,67.7-48.9V313C585.6,286,555.3,264.1,517.9,264.1z M238.8,613.6H77.7c-37.4,0-67.7,21.9-67.7,48.9V687c0,27,30.3,48.9,67.7,48.9h161.1c37.4,0,67.7-21.9,67.7-48.9v-24.5C306.5,635.5,276.2,613.6,238.8,613.6z M378.3,438.9H77.7c-37.4,0-67.7,21.9-67.7,48.9v24.4c0,27,30.3,48.9,67.7,48.9h300.6c37.4,0,67.7-21.9,67.7-48.9v-24.4C446,460.8,415.7,438.9,378.3,438.9z" />
      <path d="M828.9,581.5L667.8,418.5H990L828.9,581.5z" />
    </g>
  </Wrapper>
);

export const DownloadIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 512 512" x="0px" y="0px" {...props}>
    <g>
      <path
        d="M463.091,466.114H74.854c-11.857,0-21.497,9.716-21.497,21.497v28.688c0,11.857,9.716,21.496,21.497,21.496h388.084
    c11.857,0,21.496-9.716,21.496-21.496v-28.688C484.665,475.677,474.949,466.114,463.091,466.114z"
      />
      <path
        d="M253.94,427.635c4.208,4.208,9.716,6.35,15.147,6.35c5.508,0,11.016-2.142,15.147-6.35l147.033-147.033
    c8.339-8.338,8.339-21.955,0-30.447l-20.349-20.349c-8.339-8.339-21.956-8.339-30.447,0l-75.582,75.659V21.497
    C304.889,9.639,295.173,0,283.393,0h-28.688c-11.857,0-21.497,9.562-21.497,21.497v284.044l-75.658-75.659
    c-8.339-8.338-22.032-8.338-30.447,0l-20.349,20.349c-8.338,8.338-8.338,22.032,0,30.447L253.94,427.635z"
      />
    </g>
  </Wrapper>
);

export const TextIconSvg: React.FC<SvgProps> = (props) => (
  <Wrapper viewBox="0 0 470 470" x="0px" y="0px" {...props}>
    <g>
      <path
        d="M327.081,0H90.234C74.331,0,61.381,12.959,61.381,28.859v412.863c0,15.924,12.95,28.863,28.853,28.863H380.35
        c15.917,0,28.855-12.939,28.855-28.863V89.234L327.081,0z M333.891,43.184l35.996,39.121h-35.996V43.184z M384.972,441.723
        c0,2.542-2.081,4.629-4.635,4.629H90.234c-2.55,0-4.619-2.087-4.619-4.629V28.859c0-2.548,2.069-4.613,4.619-4.613h219.411v70.181
        c0,6.682,5.443,12.099,12.129,12.099h63.198V441.723z M128.364,128.89H334.15c5.013,0,9.079,4.066,9.079,9.079
        c0,5.013-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079C119.285,132.957,123.352,128.89,128.364,128.89z
         M343.229,198.98c0,5.012-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15
        C339.163,189.901,343.229,193.968,343.229,198.98z M343.229,257.993c0,5.013-4.066,9.079-9.079,9.079H128.364
        c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15C339.163,248.914,343.229,252.98,343.229,257.993z
         M343.229,318.011c0,5.013-4.066,9.079-9.079,9.079H128.364c-5.012,0-9.079-4.066-9.079-9.079s4.067-9.079,9.079-9.079H334.15
        C339.163,308.932,343.229,312.998,343.229,318.011z"
      />
    </g>
  </Wrapper>
);
