import axiosInstance from "../libs/axios";
import * as participantsActions from "../reducers/participants/actions";
import { errorToast } from "../libs/helpers";

export const getParticipants = () => (dispatch: any) => {
  dispatch({ type: participantsActions.fetchParticipantsAction.request });
  axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/api/v1/participants`)
    .then((response) => {
      dispatch({
        type: participantsActions.fetchParticipantsAction.success,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: participantsActions.fetchParticipantsAction.failure,
        payload: error,
      });
    });
};

export const getParticipantBySlug = (slug: string) => (dispatch: any) => {
  dispatch({ type: participantsActions.fetchParticipantBySlugAction.request });
  axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/api/v1/participants/${slug}`)
    .then((response) => {
      dispatch({
        type: participantsActions.fetchParticipantBySlugAction.success,
        payload: response.data,
      });
    })
    .catch((error) => {
      errorToast(error);
      dispatch({
        type: participantsActions.fetchParticipantBySlugAction.failure,
        payload: error,
      });
    });
};
