import { Reducer } from "redux";

import * as playerActions from "./actions";

import { IAction } from "../../interfaces/action";
import { ITrack } from "../../interfaces/common";

export interface IPlayerState {
  track: ITrack | null;
  url: null | string;
  pip: boolean;
  playing: boolean;
  controls: boolean;
  light: boolean;
  volume: number;
  muted: boolean;
  played: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  seeking: boolean;
  playedSeconds: number;
}

export const playerInitialState: IPlayerState = {
  track: null,
  url: null,
  pip: false,
  playing: false,
  controls: false,
  light: false,
  volume: 1,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  seeking: false,
  playedSeconds: 0,
};

export const playerReducers: Reducer = (
  state = playerInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    case playerActions.playAction.request: {
      return { ...state, playing: true };
    }

    case playerActions.pauseAction.request: {
      return { ...state, playing: false };
    }

    case playerActions.setTrack.request: {
      // const isFirstTrack = !state.track;

      return {
        ...state,
        track: payload.track,
        played: 0,
        loaded: 0,
        pip: false,
        // playing: isFirstTrack ? false : true,
        playing: false,
      };
    }

    case playerActions.setPlayerOptionsAction.request: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};
