// @ts-nocheck
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { api_request } from "../../api";
import { errorToast } from "../../libs/helpers";

const DashboardNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogoutHandler = () => {
    dispatch(() => {
      api_request
        .logout()
        .then(() => {
          navigate("/login");
        })
        .catch((error) => {
          errorToast(error);
        });
    });
  };

  return (
    <div className="dashboardNav">
      <NavLink to="/dashboard">Posts</NavLink>
      <NavLink to="/dashboard/gallery">Gallery</NavLink>
      <NavLink to="/dashboard/links">Links</NavLink>
      <NavLink to="/dashboard/participants">Люди</NavLink>
      <NavLink to="/dashboard/tags">Теги</NavLink>
      <NavLink to="/dashboard/tracks">Пісні</NavLink>
      <span onClick={onLogoutHandler}>logout</span>
    </div>
  );
};

export default DashboardNav;
