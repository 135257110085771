// @ts-nocheck
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import routes from "./routes";
import store from "./reducers";
import Layout from "./components/Layouts/main";
import Player from "./components/Player";
import Tracks from "./pages/Tracks/tracks";

import "./App.scss";
import "./pages/Discography/discography.scss";

import { LongRightArrowSvg, LongLeftArrowSvg } from "./components/Icons/index";

import moment from "moment";
// @ts-ignore
import localization from "moment/locale/uk";
import { Helmet } from "react-helmet";
import komilfobandImg from "./static/komilfoband.jpg";
import { useStateWithLocalStorage } from "./libs/hooks";
import { ToastContainer } from "react-toastify";
import MainNav from "./components/MainNav";
// @ts-ignore
moment().locale("uk", localization);

const App: React.FC = () => {
  // const [isOpen, setIsOpen] = useState(true);
  const [isOpen, setIsOpen] = useStateWithLocalStorage("isOpen");

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Provider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Komilfo band. Оfficial web site.</title>
        <link rel="canonical" href="https://komilfo.band/" />
        <meta name="description" content="Komilfo band official" />
        {/* OpenGraph tags */}
        <meta name="og:url" content="https://komilfo.band/" />
        <meta name="og:title" content="Komilfo band official" />
        <meta name="og:description" content="Komilfo band official" />
        <meta name="og:image" content={komilfobandImg} />
        <meta name="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta name="twitter:title" content="Komilfo band official" />
        <meta name="twitter:description" content="Komilfo band official" />
        <meta name="twitter:image" content={komilfobandImg} />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <Router>
        <div className="main">
          <ToastContainer />

          <MainNav />

          <div className="container">
            <Layout>
              <Routes>
                {routes.map((route) => (
                  <Route
                    key={route.name}
                    path={route.url}
                    element={<route.component />}
                    exact={route.exact}
                  />
                ))}
              </Routes>
            </Layout>
          </div>
        </div>

        <div className={`app-player${isOpen ? " isOpen" : ""}`}>
          <div className="playerDraggableBtn" onClick={toggleList}>
            <div className="horizontalToggleList">
              {isOpen ? (
                <LongRightArrowSvg width={15} height={30} />
              ) : (
                <LongLeftArrowSvg width={15} height={30} />
              )}
            </div>
          </div>
          <Tracks togglePlayer={toggleList} isPlayerOpen={isOpen} />
          <Player />
        </div>
      </Router>
    </Provider>
  );
};

export default App;
