import * as linksActions from "../reducers/links/actions";
import { api_request } from "../api";
import { AnyAction, Dispatch } from "redux";

export const getLinks = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({ type: linksActions.fetchLinks.request });
  api_request
    .getLinks()
    .then((response) => {
      dispatch({
        type: linksActions.fetchLinks.success,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: linksActions.fetchLinks.failure,
        payload: error,
      });
    });
};
