import { Reducer } from "redux";

import * as postsActions from "../../reducers/links/actions";
import { IAction } from "../../interfaces/action";

export interface ILink {
  id?: number;
  title: string;
  icon: string;
  order: string;
  text: string;
  url: string;
}

export interface ILinksState {
  links: ILink[];
  isLoading: boolean;
}

export const linkInitialState: ILinksState = {
  links: [],
  isLoading: false,
};

export const linksReducers: Reducer = (
  state = linkInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    case postsActions.fetchLinks.request: {
      return { ...linkInitialState, isLoading: true };
    }
    case postsActions.fetchLinks.success: {
      return { ...state, links: payload, isLoading: false };
    }
    case postsActions.fetchLinks.failure: {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};
