import {
  applyMiddleware,
  combineReducers,
  createStore,
  ReducersMapObject,
  compose,
} from "redux";

import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import {
  tracksReducers,
  ITrackState,
  trackInitialState,
} from "./tracks/reducer";

import {
  albumsReducers,
  IAlbumsState,
  albumsInitialState,
} from "./albums/reducer";

import { userReducers } from "./users/reducer";
import {
  playerReducers,
  IPlayerState,
  playerInitialState,
} from "./player/reducer";
import {
  participantsReducers,
  IParticipantsState,
  participantInitialState,
} from "./participants/reducer";
import { IPostsState, postInitialState, postsReducers } from "./posts/reducer";
import { ILinksState, linkInitialState, linksReducers } from "./links/reducer";
import { IAdminState, adminInitialState, adminReducers } from "./admin/reducer";
import { IUser } from "../interfaces/common";

export interface IState {
  user?: IUser;
  tracks: ITrackState;
  albums: IAlbumsState;
  player: IPlayerState;
  participants: IParticipantsState;
  posts: IPostsState;
  links: ILinksState;
  admin: IAdminState;
}

const stateInitialValue: IState = {
  tracks: trackInitialState,
  albums: albumsInitialState,
  player: playerInitialState,
  participants: participantInitialState,
  posts: postInitialState,
  links: linkInitialState,
  admin: adminInitialState,
};

const rootReducers: ReducersMapObject = {
  user: userReducers,
  tracks: tracksReducers,
  albums: albumsReducers,
  player: playerReducers,
  participants: participantsReducers,
  posts: postsReducers,
  links: linksReducers,
  admin: adminReducers,
};

const store = createStore(
  combineReducers(rootReducers),
  stateInitialValue,
  process.env.NODE_ENV === "production"
    ? compose(applyMiddleware(thunk))
    : composeWithDevTools(applyMiddleware(thunk))
);

/* eslint-enable */
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
