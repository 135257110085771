import { Reducer } from "redux";
import moment from "moment";

import * as postsActions from "../../reducers/posts/actions";
import { IAction } from "../../interfaces/action";
import { IMeta } from "../admin/reducer";
import { acceptChangedItemToList } from "../../libs/reducers";
import { IPost } from "../../interfaces/common";

export interface IPostsState {
  posts: IPost[];
  isLoading: boolean;
  tags: string;
  meta: IMeta;
}

export const postInitialState: IPostsState = {
  posts: [],
  isLoading: false,
  tags: "",
  meta: {
    total: 0,
    page: 1,
    lastPage: 1,
    perPage: 10,
  },
};

export const postsReducers: Reducer = (
  state = postInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    case postsActions.fetchPosts.request: {
      return {
        ...state,
        posts: [...state.posts],
        meta: state.meta,
        isLoading: true,
      };
    }
    case postsActions.fetchPosts.success: {
      const meta = {
        total: parseInt(payload.meta.total || postInitialState.meta.total),
        page: payload.meta.page || postInitialState.meta.page,
        perPage: payload.meta.perPage || postInitialState.meta.perPage,
      };

      const postsSlugs = state.posts.map((p: IPost) => p.slug);
      const newPosts = (payload.posts || []).filter(
        (newPost: IPost) => !postsSlugs.includes(newPost.slug)
      );

      const posts =
        meta.page === 1
          ? [...payload.posts]
          : [...state.posts, ...newPosts].sort((a: IPost, b: IPost) =>
              moment(a.publishedAt).isBefore(b.publishedAt) ? 1 : -1
            );

      return { ...state, posts, meta, tags: payload.tags, isLoading: false };
    }

    case postsActions.fetchPosts.failure: {
      return {
        ...state,
        posts: [...state.posts],
        meta: state.meta,
        isLoading: false,
      };
    }

    case postsActions.fetchPostBySlug.request: {
      return { ...state, isLoading: true };
    }
    case postsActions.fetchPostBySlug.success: {
      return {
        ...state,
        posts: acceptChangedItemToList(state.posts, payload),
        meta: state.meta,
        isLoading: false,
      };
    }
    case postsActions.fetchPostBySlug.failure: {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};
