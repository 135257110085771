import React from "react";
import { ITrack } from "../../interfaces/common";
import { getAlbumCoverPath, getUrl } from "../../libs/helpers";
import { Link } from "react-router-dom";

type TrackDetailsHeadProps = {
  track: ITrack;
};

const TrackDetailsHead = ({ track }: TrackDetailsHeadProps) => {
  return (
    <div className="album-head">
      <div className="album-info__cover" draggable="true">
        {track.album ? (
          <img
            draggable="false"
            loading="lazy"
            src={getAlbumCoverPath(track.album.cover)}
            alt={track.album.title}
          />
        ) : null}
      </div>
      <div className="album-info__text">
        <span className="title" draggable="true" data-encore-id="text">
          <Link
            draggable="false"
            className="name"
            to={getUrl("TrackDetails", { slug: track.slug })}
          >
            {track.title}
          </Link>
        </span>
        {track.album ? (
          <div className="details">
            {/* <span>{track.album.title}</span> */}

            <Link to={getUrl("AlbumDetails", { slug: track.album.slug })}>
              {track.album.title}
            </Link>

            <span className="year">{track.album.year}</span>
            {/* <span className="count">
            {track.album.tracks &&
              track.album.tracks.length +
                (track.album.tracks.length > 1 ? " songs" : " song")}
          </span> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TrackDetailsHead;
