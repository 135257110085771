import * as postsActions from "../reducers/posts/actions";
import { errorToast } from "../libs/helpers";
import { api_request } from "../api";
import { IGetPostsArgs } from "../interfaces/common";
import { Dispatch } from "redux";

interface GetPostBySlugArgs {
  slug: string;
}

export const getPosts =
  ({ page, tags, perPage }: IGetPostsArgs) =>
  (dispatch: Dispatch) => {
    dispatch({ type: postsActions.fetchPosts.request });
    api_request
      .getPosts({ page, tags, perPage })
      .then((response) => {
        dispatch({
          type: postsActions.fetchPosts.success,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorToast(error);
        dispatch({ type: postsActions.fetchPosts.failure, payload: error });
      });
  };

export const getPostBySlug =
  ({ slug }: GetPostBySlugArgs) =>
  (dispatch: Dispatch) => {
    dispatch({ type: postsActions.fetchPostBySlug.request });
    api_request
      .getPostBySlug({ slug })
      .then((response) => {
        dispatch({
          type: postsActions.fetchPostBySlug.success,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorToast(error);
        dispatch({
          type: postsActions.fetchPostBySlug.failure,
          payload: error,
        });
      });
  };
