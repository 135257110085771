import { Reducer } from "redux";

import * as adminActions from "../../reducers/admin/actions";
import { IAction } from "../../interfaces/action";
import { postInitialState } from "../posts/reducer";
import { IGalleryOwners } from "../../interfaces/dashboard";
import {
  acceptChangedItemToList,
  removeItemFromList,
} from "../../libs/reducers";
import { IAlbum } from "../../interfaces/common";

export interface IGalleryItem {
  id?: number;
  filename: string;
  origin_name?: string;
  content_type: string;
  createdAt: string;
}

export interface IAdminGalleryFilters {
  owner: IGalleryOwners;
}

export interface IAdminLink {
  id?: number;
  title: string;
  icon: string;
  url: string;
  order: number;
}

export interface IAdminUser {
  id?: number;
  username: string;
}

export interface IAdminPost {
  id?: number;
  title: string;
  slug: string;
  text: string;
  media: string;
  publishedAt: string;
  isPublished: boolean;
  user?: IAdminUser;
  files?: IGalleryItem[];
  cover?: IGalleryItem;
  tags?: ITag[];
}

export interface IAdminParticipant {
  id?: number;
  firstname: string;
  lastname: string;
  cover: string;
  bio: string;
  text: string;
  slug: string;
  files?: IGalleryItem[];
  avatar?: IGalleryItem;
}

export interface ITag {
  id?: number;
  slug: string;
  title: string;
}

export interface IAdminTrack {
  id?: number;
  title: string;
  album: IAlbum | null | undefined;
  slug: string;
  src: string;
  description: string;
  lyrics: string;
}

export interface IMeta {
  total: number;
  page: number;
  perPage: number;
  lastPage: number;
}

export interface IAdminState {
  gallery: {
    items: IGalleryItem[];
    isLoading: boolean;
    meta: IMeta;
    filters: IAdminGalleryFilters;
  };
  links: {
    links: IAdminLink[];
    isLoading: boolean;
  };
  posts: {
    posts: IAdminPost[];
    isLoading: boolean;
    meta: IMeta;
  };
  participants: {
    participants: IAdminParticipant[];
    isLoading: boolean;
  };
  tags: {
    tags: ITag[];
    isLoading: boolean;
  };
  user?: {
    firstname: string;
  };
  tracks: {
    tracks: IAdminTrack[];
    isLoading: boolean;
  };
}

export const adminInitialState: IAdminState = {
  gallery: {
    items: [],
    isLoading: false,
    meta: {
      total: 0,
      page: 1,
      lastPage: 1,
      perPage: 20,
    },
    filters: {
      owner: IGalleryOwners.Me,
    },
  },
  links: {
    links: [],
    isLoading: false,
  },
  posts: {
    posts: [],
    isLoading: false,
    meta: {
      total: 0,
      page: 1,
      lastPage: 1,
      perPage: 10,
    },
  },
  participants: {
    participants: [],
    isLoading: false,
  },
  tags: {
    tags: [],
    isLoading: false,
  },
  tracks: {
    tracks: [],
    isLoading: false,
  },
};

export const adminReducers: Reducer = (
  state = adminInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    // ADMIN PARTICIPANTS

    case adminActions.fetchDashboardParticipants.request: {
      return {
        ...state,
        participants: {
          participants: state.participants.participants,
          isLoading: false,
        },
      };
    }
    case adminActions.fetchDashboardParticipants.success: {
      return {
        ...state,
        participants: { participants: payload, isLoading: false },
      };
    }
    case adminActions.fetchDashboardParticipants.failure: {
      return { ...state, participants: { participants: [], isLoading: false } };
    }

    case adminActions.fetchDashboardParticipant.request:
    case adminActions.sendDashboardParticipant.request:
    case adminActions.attachDashboardFileToParticipant.request:
    case adminActions.detachDashboardFileFromParticipant.request:
    case adminActions.setAvatarFileToParticipant.request:
    case adminActions.deleteAvatar.request: {
      return {
        ...state,
        participants: {
          participants: [...state.participants.participants],
          isLoading: false,
        },
      };
    }
    case adminActions.fetchDashboardParticipant.success:
    case adminActions.sendDashboardParticipant.success:
    case adminActions.attachDashboardFileToParticipant.success:
    case adminActions.detachDashboardFileFromParticipant.success:
    case adminActions.setAvatarFileToParticipant.success:
    case adminActions.deleteAvatar.success: {
      const participants = acceptChangedItemToList(
        state.participants.participants,
        payload
      );
      return { ...state, participants: { participants, isLoading: false } };
    }
    case adminActions.fetchDashboardParticipant.failure:
    case adminActions.sendDashboardParticipant.failure:
    case adminActions.attachDashboardFileToParticipant.failure:
    case adminActions.detachDashboardFileFromParticipant.failure:
    case adminActions.setAvatarFileToParticipant.failure:
    case adminActions.deleteAvatar.failure: {
      return {
        ...state,
        participants: {
          participants: [...state.participants.participants],
          isLoading: false,
        },
      };
    }

    case adminActions.deleteDashboardParticipant.request: {
      return {
        ...state,
        participants: {
          participants: state.participants.participants,
          isLoading: false,
        },
      };
    }
    case adminActions.deleteDashboardParticipant.success: {
      return {
        ...state,
        participants: {
          ...state.participants,
          participants: state.participants.participants.filter(
            (p: IAdminParticipant) => p.id !== payload.id
          ),
          isLoading: false,
        },
      };
    }
    case adminActions.deleteDashboardParticipant.failure: {
      return {
        ...state,
        participants: {
          participants: [...state.participants.participants],
          isLoading: false,
        },
      };
    }

    // ADMIN POST

    case adminActions.fetchAdminPosts.request: {
      return {
        ...state,
        posts: {
          posts: [],
          meta: adminInitialState.posts.meta,
          isLoading: true,
        },
      };
    }
    case adminActions.fetchAdminPosts.success: {
      const posts = payload.data || [];
      const meta = {
        total: parseInt(payload.total || adminInitialState.posts.meta.total),
        page: payload.page || adminInitialState.posts.meta.page,
        lastPage: payload.lastPage || adminInitialState.posts.meta.lastPage,
        perPage: payload.perPage || adminInitialState.posts.meta.perPage,
      };
      return {
        ...state,
        posts: {
          ...state.posts,
          posts,
          meta,
          isLoading: false,
        },
      };
    }
    case adminActions.fetchAdminPosts.failure: {
      return {
        ...state,
        posts: {
          posts: [],
          meta: adminInitialState.posts.meta,
          isLoading: false,
        },
      };
    }

    case adminActions.fetchDashboardPost.request:
    case adminActions.sendDashboardPost.request:
    case adminActions.sendDashboardPostPublished.request:
    case adminActions.attachDashboardFileToPost.request:
    case adminActions.detachDashboardFileFromPost.request:
    case adminActions.attachDashboardTagToPost.request:
    case adminActions.detachDashboardTagFromPost.request:
    case adminActions.setCoverFileToPost.request:
    case adminActions.togglePublishedPost.request:
    case adminActions.deleteCoverPost.request: {
      return {
        ...state,
        posts: { ...state.posts, posts: state.posts.posts, isLoading: true },
      };
    }
    case adminActions.fetchDashboardPost.success:
    case adminActions.sendDashboardPost.success:
    case adminActions.sendDashboardPostPublished.success:
    case adminActions.attachDashboardFileToPost.success:
    case adminActions.detachDashboardFileFromPost.success:
    case adminActions.attachDashboardTagToPost.success:
    case adminActions.detachDashboardTagFromPost.success:
    case adminActions.setCoverFileToPost.success:
    case adminActions.togglePublishedPost.success:
    case adminActions.deleteCoverPost.success: {
      const posts = acceptChangedItemToList(state.posts.posts, payload);
      return { ...state, posts: { ...state.posts, posts, isLoading: false } };
    }
    case adminActions.fetchDashboardPost.failure:
    case adminActions.sendDashboardPost.failure:
    case adminActions.sendDashboardPostPublished.failure:
    case adminActions.attachDashboardFileToPost.failure:
    case adminActions.detachDashboardFileFromPost.failure:
    case adminActions.attachDashboardTagToPost.failure:
    case adminActions.detachDashboardTagFromPost.failure:
    case adminActions.setCoverFileToPost.failure:
    case adminActions.togglePublishedPost.failure:
    case adminActions.deleteCoverPost.failure: {
      return {
        ...state,
        posts: { ...state.posts, posts: state.posts.posts, isLoading: false },
      };
    }

    // ADMIN TAGS
    case adminActions.fetchDashboardTags.request: {
      return { ...state, tags: { tags: state.tags.tags, isLoading: true } };
    }
    case adminActions.fetchDashboardTags.success: {
      return { ...state, tags: { tags: payload, isLoading: false } };
    }
    case adminActions.fetchDashboardTags.failure: {
      return { ...state, tags: { tags: state.tags.tags, isLoading: false } };
    }

    case adminActions.sendDashboardTag.request: {
      return { ...state, tags: { tags: state.tags.tags, isLoading: true } };
    }
    case adminActions.sendDashboardTag.success: {
      return {
        ...state,
        tags: {
          tags: acceptChangedItemToList(state.tags.tags, payload),
          isLoading: false,
        },
      };
    }
    case adminActions.sendDashboardTag.failure: {
      return { ...state, tags: { tags: state.tags.tags, isLoading: false } };
    }

    case adminActions.deleteDashboardTag.request: {
      return { ...state, tags: { tags: state.tags.tags, isLoading: true } };
    }
    case adminActions.deleteDashboardTag.success: {
      return {
        ...state,
        tags: {
          tags: removeItemFromList(state.tags.tags, payload),
          isLoading: false,
        },
      };
    }
    case adminActions.deleteDashboardTag.failure: {
      return { ...state, tags: { tags: state.tags.tags, isLoading: false } };
    }

    // fetchDashboardTracks
    case adminActions.fetchDashboardTracks.request: {
      return {
        ...state,
        tracks: { tracks: [...state.tracks.tracks], isLoading: true },
      };
    }
    case adminActions.fetchDashboardTracks.success: {
      return { ...state, tracks: { tracks: [...payload], isLoading: false } };
    }
    case adminActions.fetchDashboardTracks.failure: {
      return {
        ...state,
        tracks: { tracks: [...state.tracks.tracks], isLoading: false },
      };
    }

    case adminActions.sendDashboardTrack.request: {
      return {
        ...state,
        tracks: { tracks: [...state.tracks.tracks], isLoading: true },
      };
    }
    case adminActions.sendDashboardTrack.success: {
      return {
        ...state,
        tracks: {
          tracks: acceptChangedItemToList(state.tracks.tracks, payload),
          isLoading: false,
        },
      };
    }
    case adminActions.sendDashboardTrack.failure: {
      return {
        ...state,
        tracks: { tracks: [...state.tracks.tracks], isLoading: false },
      };
    }

    // Admin Gallery
    case adminActions.fetchAdminGallery.request: {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [...state.gallery.items],
          isLoading: true,
        },
      };
    }
    case adminActions.fetchAdminGallery.success: {
      const meta = {
        total: parseInt(payload.total || postInitialState.meta.total),
        page: payload.page || postInitialState.meta.page,
        lastPage: payload.lastPage || postInitialState.meta.lastPage,
        perPage: payload.perPage || postInitialState.meta.perPage,
      };

      const filters = {
        owner: payload.filters.owner,
      };

      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: payload.data,
          meta,
          filters,
          isLoading: false,
        },
      };
    }
    case adminActions.fetchAdminGallery.failure: {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [...state.gallery.items],
          isLoading: false,
        },
      };
    }

    case adminActions.uploadAdminGalleryItem.request: {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [...state.gallery.items],
          isLoading: true,
        },
      };
    }
    case adminActions.uploadAdminGalleryItem.success: {
      const galleryItems = state.gallery.items.find(
        (f: IGalleryItem) => f.filename === payload.filename
      )
        ? state.gallery.items.map((f: IGalleryItem) =>
            f.filename === payload.filename ? payload : f
          )
        : [...state.gallery.items, payload];
      return {
        ...state,
        gallery: { ...state.gallery, items: galleryItems, isLoading: false },
      };
    }
    case adminActions.uploadAdminGalleryItem.failure: {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [...state.gallery.items],
          isLoading: false,
        },
      };
    }

    case adminActions.deleteAdminGalleryItem.request: {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [...state.gallery.items],
          isLoading: true,
        },
      };
    }
    case adminActions.deleteAdminGalleryItem.success: {
      const galleryItems = state.gallery.items.filter(
        (file: IGalleryItem) => file.filename !== payload.filename
      );
      return {
        ...state,
        gallery: { ...state.gallery, items: galleryItems, isLoading: true },
      };
    }
    case adminActions.deleteAdminGalleryItem.failure: {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          items: [...state.gallery.items],
          isLoading: false,
        },
      };
    }

    // Dashboard Links
    case adminActions.fetchDashboardLinks.request: {
      return {
        ...state,
        links: { ...state.links, links: state.links.links, isLoading: true },
      };
    }
    case adminActions.fetchDashboardLinks.success: {
      return { ...state, links: { links: payload, isLoading: false } };
    }
    case adminActions.fetchDashboardLinks.failure: {
      return { ...state, links: { links: [], isLoading: false } };
    }

    case adminActions.fetchDashboardLink.request: {
      return {
        ...state,
        links: { ...state.links, links: state.links.links, isLoading: true },
      };
    }
    case adminActions.fetchDashboardLink.success: {
      const links = state.links.links.find(
        (l: IAdminLink) => l.id === payload.id
      )
        ? state.links.links.map((l: IAdminLink) =>
            l.id === payload.id ? payload : l
          )
        : [...state.links.links, payload];
      return { ...state, links: { links: links, isLoading: false } };
    }
    case adminActions.fetchDashboardLink.failure: {
      return { ...state, links: { links: [], isLoading: false } };
    }

    case adminActions.sendDashboardLink.request: {
      return {
        ...state,
        links: { ...state.links, links: state.links.links, isLoading: true },
      };
    }
    case adminActions.sendDashboardLink.success: {
      const links = state.links.links.find(
        (l: IAdminLink) => l.id === payload.id
      )
        ? state.links.links.map((l: IAdminLink) =>
            l.id === payload.id ? payload : l
          )
        : [state.links.links, payload];
      return { ...state, links: { links: links, isLoading: false } };
    }
    case adminActions.sendDashboardLink.failure: {
      return { ...state, links: { links: [], isLoading: false } };
    }

    case adminActions.deleteDashboardLink.request: {
      return { ...state, links: { ...state.links, isLoading: true } };
    }
    case adminActions.deleteDashboardLink.success: {
      // payload = link_id
      return {
        ...state,
        links: {
          ...state.links,
          links: removeItemFromList(state.links.links, payload),
          isLoading: false,
        },
      };
    }
    case adminActions.deleteDashboardLink.failure: {
      return { ...state, links: { ...state.links, isLoading: false } };
    }

    case adminActions.moveDashboardLinkOrderUp.request: {
      return { ...state, links: { ...state.links, isLoading: true } };
    }
    case adminActions.moveDashboardLinkOrderUp.success: {
      return { ...state, links: { ...state.links, isLoading: false } };
    }
    case adminActions.moveDashboardLinkOrderUp.failure: {
      return { ...state, links: { ...state.links, isLoading: false } };
    }

    case adminActions.moveDashboardLinkOrderDown.request: {
      return { ...state, links: { ...state.links, isLoading: true } };
    }
    case adminActions.moveDashboardLinkOrderDown.success: {
      return { ...state, links: { ...state.links, isLoading: false } };
    }
    case adminActions.moveDashboardLinkOrderDown.failure: {
      return { ...state, links: { ...state.links, isLoading: false } };
    }

    default:
      return state;
  }
};
