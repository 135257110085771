interface T {
  slug: string;
}

export const acceptChangedItemToList = (items: T[], item: T): T[] => {
  const itemIndex = items.findIndex((it: T) => it.slug === item.slug);
  if (itemIndex >= 0) {
    items[itemIndex] = item;
    return [...items];
  } else {
    return [...items, item];
  }
};

export const removeItemFromList = (items: T[], item: T): T[] => {
  return [...items.filter((it: T) => it.slug !== item.slug)];
};
