// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getParticipantBySlug } from "../../actions/participants";
import { IState } from "../../reducers";
import { getUrl } from "../../libs/helpers";
import { DownloadIconSvg } from "../../components/Icons";

import "../Crew/crewStyles.scss";

import { getImagePath } from "../../libs/helpers";
// import Lightbox from "yet-another-react-lightbox";
import Loading from "../../components/loading";
import { IParticipant } from "../../interfaces/common";

// import Lightbox from "react-spring-lightbox";

const ParticipantShow: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { participants, isLoading } = useSelector(
    (state: IState) => state.participants
  );
  const participant: IParticipant | undefined = participants.find(
    (participant: IParticipant) => slug === participant.slug
  );

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getParticipantBySlug(slug));
  }, [dispatch, slug]);

  const images: string[] = [];
  if (participant?.cover) {
    images.push(participant.cover.filename);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="participant_show">
      {participant && (
        <>
          <div className="breadcrumbs">
            <Link to={getUrl("Posts")}>Блог</Link> &gt;&gt;{" "}
            <Link to={getUrl("Crew")}>Гурт</Link> &gt;&gt;{" "}
            <Link to={getUrl("CrewShow", { slug: participant.slug })}>
              {participant.firstname} {participant.lastname}
            </Link>
          </div>

          <div className={"info"}>
            {participant?.cover && (
              <div className="avatar">
                <img
                  src={getImagePath(participant.cover.filename)}
                  alt={`${participant.firstname} ${participant.lastname}`}
                  onClick={() => {
                    setIsOpen(true);
                    // setPhotoIndex(0);
                  }}
                />
              </div>
            )}
            <h3>
              {participant.firstname} {participant.lastname}
            </h3>
            <p className="bio">{participant.bio}</p>
            <p
              className="text"
              dangerouslySetInnerHTML={{ __html: participant.text }}
            />

            <div className="images">
              {/* {participant.cover && (
                <div
                  key={file.filename}
                  className="image"
                  onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(
                      images.findIndex(
                        (filename: string) => filename === file.filename
                      )
                    );
                  }}
                >
                  <img
                    src={getImageThumbPath(file.filename)}
                    alt={file.filename}
                  />
                </div>
              )} */}
            </div>
          </div>
          {/* {isOpen && (
            <Lightbox
              isOpen={isOpen}
              onPrev={images[(photoIndex + images.length - 1) % images.length]}
              onNext={images[(photoIndex + 1) % images.length]}
              images={images.map((image) => ({
                src: getImagePath(image),
                alt: "",
              }))}
              currentIndex={0}
              className="cool-class"
              style={{ background: "rgba(0, 0, 0, .8)" }}
              onClose={() => setIsOpen(false)}
            />
          )} */}
        </>
      )}
    </div>
  );
};

export default ParticipantShow;
