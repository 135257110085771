import {
  IGetPostDetailsArgs,
  IGetPostsArgs,
  ILogin,
} from "./interfaces/common";
import axiosInstance from "./libs/axios";

const api_prefix = `${process.env.REACT_APP_API_URL}/api/v1`;

export const api_request = {
  login({ email, password }: ILogin) {
    return axiosInstance.post(`${api_prefix}/login`, { email, password });
  },
  logout() {
    return axiosInstance.post(`${api_prefix}/dashboard/logout`);
  },
  getAlbums() {
    return axiosInstance.get(`${api_prefix}/discography`);
  },
  getAlbumBySlug(slug = "") {
    return axiosInstance.post(`${api_prefix}/discography/albums/${slug}`);
  },
  getPosts({ page, tags, perPage }: IGetPostsArgs) {
    return axiosInstance.get(
      `${api_prefix}/posts?page=${page}${perPage ? `&perPage=${perPage}` : ""}${
        tags ? `&tags=${tags}` : ""
      }`
    );
  },
  getLinks() {
    return axiosInstance.get(`${api_prefix}/links`);
  },
  getPostBySlug({ slug }: IGetPostDetailsArgs) {
    return axiosInstance.get(`${api_prefix}/posts/${slug}`);
  },
  getProfile() {
    return axiosInstance.post(`${api_prefix}/profile`);
  },
};
