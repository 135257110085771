import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import queryString from "query-string";
import { IState } from "../../reducers";
import { getPosts } from "../../actions/posts";
import { useLocation } from "react-router-dom";
import "./postStyles.scss";

import { Helmet } from "react-helmet";
import { Waypoint } from "react-waypoint";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/loading";
import { IPost } from "../../interfaces/common";
import Post from "../../components/Posts/post";

const PostDetails: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { posts, meta, tags, isLoading } = useSelector(
    (state: IState) => state.posts
  );

  const location = useLocation();

  useEffect(() => {
    dispatch(
      getPosts({
        page: 1,
        perPage: 10,
        tags: String(queryString.parse(location.search).tags || ""),
      })
    );
  }, [dispatch, location]);

  const loadMoreContent = () => {
    if (meta.total > meta.page * meta.perPage) {
      dispatch(
        getPosts({
          page: meta.page + 1,
          perPage: 10,
          tags: String(queryString.parse(location.search).tags || ""),
        })
      );
    }
  };

  return (
    <section className="posts">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Komilfo band. Оfficial web site.</title>
        <link rel="canonical" href={process.env.REACT_APP_HOST_URL} />
        <meta name="description" content="Komilfo band. Оfficial web site." />
      </Helmet>

      {tags && <div className="filters">Posts filter: #{tags} </div>}

      {posts.map((post: IPost) => {
        return (
          <div className="post" key={post.slug}>
            <Post post={post} />
          </div>
        );
      })}

      <Waypoint key={`cursor${meta.page}`} onEnter={loadMoreContent} />

      {isLoading && <Loading />}

      <Footer />
    </section>
  );
};

export default PostDetails;
