import { toast as toastNative } from "react-toastify";
import routes from "../routes";

export const getUrl = (name: string, params: object = {}) => {
  const getCurrentRouteObject = [...routes].find(
    (route) => route.name === name
  );
  if (getCurrentRouteObject) {
    let getCurrentUrl = getCurrentRouteObject.url;

    Object.keys(params).forEach((k) => {
      // @ts-ignore
      getCurrentUrl = getCurrentUrl.replace(`:${k}`, params[k]);
    });
    return getCurrentUrl;
  }

  return "";
};

/**
 * Get full track url (to aws s3) by filename
 * @param filename
 */
export const getTrackUrlByFalename = (filename: string): string => {
  return new URL(`audio/${filename}`, process.env.REACT_APP_TRACK_URI).href;
};

export function shuffle(a: []) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

export function toMMSS(inputSeconds: number) {
  const hours = Math.floor(inputSeconds / 3600);
  const minutes = Math.floor((inputSeconds - hours * 3600) / 60);
  const seconds = inputSeconds - hours * 3600 - minutes * 60;

  return `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
}

export const errorToast = (error: any) => {
  let msg = "";
  if (error.response && error.response.status === 401) {
    // history.push('/login');
    msg = "Not logged in";
  } else if (
    error.response &&
    error.response.data &&
    error.response.data &&
    Array.isArray(error.response.data)
  ) {
    // Validation form
    msg = "";
    error.response.data.forEach((m: any) => {
      msg += m.message + "\n";
    });
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    msg = error.response.data.error.message;
  } else if (!error || !error.response || !error.response.data) {
    msg = "Unknown Error.";
  } else if (!!error.response.data.message) {
    msg = error.response.data.message;
  } else if (typeof error.response.data === "string") {
    msg = error.response.data;
  }

  // error.response

  if (!msg) {
    msg = "No Server Answer.";
  }

  toastNative.error(msg, {
    position: toastNative.POSITION.TOP_RIGHT,
  });
};

export const getImagePath = (filename: string): string => {
  return `${process.env.REACT_APP_MEDIA_URI}/upload/images/${filename}`;
};

export const getImageOriginalPath = (filename: string): string => {
  return `${process.env.REACT_APP_MEDIA_URI}/upload/images/origin/${filename}`;
};

export const getImageThumbPath = (filename: string): string => {
  return `${process.env.REACT_APP_MEDIA_URI}/upload/images/thumb/${filename}`;
};

export const getAlbumCoverPath = (filename: string): string => {
  return `${process.env.REACT_APP_TRACK_URI}/uploads/albums/${filename}`;
};

export const youTubeVideoRegex =
  /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
export const facebookVideoRegex =
  /(?:facebook\.com\/(?:video\.php\?v=\d+|.*?\/videos\/\d+))/gi;
export const mixcloudVideoRegex = /(?:mixcloud\.com\/)/gi;

/**
 * The `formatTime` function in TypeScript takes a number of seconds and returns a formatted string
 * representing the time in hours, minutes, and seconds.
 * @param {number} seconds - The `formatTime` function takes a number of seconds as input and converts
 * it into a formatted time string in the format "HH:MM:SS".
 * @returns The `formatTime` function takes a number of seconds as input and returns a formatted string
 * representing the time in the format "HH:MM:SS" (hours:minutes:seconds).
 */
export function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${
    hours > 0 ? formattedHours + ":" : ""
  }${formattedMinutes}:${formattedSeconds}`;
}
