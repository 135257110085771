import React, { useEffect, useRef, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import { getUrl } from "../libs/helpers";

import "../components/stylesMainNav.scss";
import mainLogo from "../static/komilfo_logo.png";
import { DropDownMenuIconSvg } from "./Icons";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../reducers";
import { ILink } from "../reducers/links/reducer";

import { sourcesLinks } from "../pages/Dashboard/Links/linkEdit";
import { useOnClickOutside } from "../libs/hooks";

const MainNav: React.FC = () => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { links } = useSelector((state: IState) => state.links);
  // const { user } = useSelector((state: IState) => state);
  const [isDropDownOpen, setDropDownState] = useState(false);

  useOnClickOutside(ref, () => {
    setDropDownState(false);
  });

  const menu = [
    {
      title: "Треки",
      routeName: "Discography",
    },
    {
      title: "Блог",
      routeName: "Posts",
    },
    {
      title: "Гурт",
      routeName: "Crew",
    },
  ];

  return (
    <header className="header">
      <div className="header-inner">
        <Link className="logo" to={getUrl("Index")}>
          <img src={mainLogo} alt="logo" />
        </Link>

        <section className="rightSide">
          <ul className="navMenu">
            {menu.map((menuItem) => (
              <li key={menuItem.routeName}>
                <NavLink to={getUrl(menuItem.routeName)}>
                  {menuItem.title}
                </NavLink>
              </li>
            ))}
          </ul>

          <ul className="social">
            {!!links &&
              links.map((link: ILink) => {
                const IconSVG: any = sourcesLinks[link.icon];
                return (
                  <li key={link.url}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.url}
                    >
                      <IconSVG />
                    </a>
                  </li>
                );
              })}
          </ul>

          <div
            className="drop-down-menu-btn"
            onClick={(e: any) => {
              setDropDownState(!isDropDownOpen);
            }}
          >
            <DropDownMenuIconSvg />
          </div>

          {/*https://usehooks.com/useOnClickOutside/*/}

          <div ref={ref} onClick={() => setDropDownState(!isDropDownOpen)}>
            <ul
              className={`drop-down-menu ${
                isDropDownOpen ? "isDropDownOpen" : ""
              }`}
            >
              <li className="empty"></li>
              {menu.map((menuItem) => (
                <li key={menuItem.routeName}>
                  <Link to={getUrl(menuItem.routeName)}>{menuItem.title}</Link>
                </li>
              ))}
              <li className="social-dd-links">
                {links.map((link: ILink) => {
                  const IconSVG: any = sourcesLinks[link.icon];
                  return (
                    <a
                      rel="noopener noreferrer"
                      href={link.url}
                      target="_blank"
                      key={link.url}
                    >
                      <IconSVG />
                    </a>
                  );
                })}
              </li>
            </ul>
          </div>
        </section>
      </div>
    </header>
  );
};

export default MainNav;
