import Discography from "./pages/Discography/Discography";
import AlbumDetails from "./pages/Albums/AlbumDetails";
import Posts from "./pages/Posts/posts";
import PostDetails from "./pages/Posts/postDetails";
// import Login from "./pages/Auth/login";
import Crew from "./pages/Crew/crew";
import ParticipantShow from "./pages/Participants/ParticipantShow";
import TrackDetails from "./pages/Tracks/trackDetails";

// import Dashboard from "./pages/Dashboard/Post/posts";
// import DashboardLinks from "./pages/Dashboard/Links/links";
// import DashboardLinkEdit from "./pages/Dashboard/Links/linkEdit";
// import DashboardParticipants from "./pages/Dashboard/Participants/participants";
// import DashboardParticipantEdit from "./pages/Dashboard/Participants/participantEdit";

// import DashboardTags from "./pages/Dashboard/Tags/tags";
// import DashboardTagEdit from "./pages/Dashboard/Tags/tagEdit";

// import DashboardTracks from "./pages/Dashboard/Tracks/tracks";
// import DashboardTrack from "./pages/Dashboard/Tracks/trackEdit";

// import DashboardPostEdit from "./pages/Dashboard/Post/postEdit";
// import DashboardGallery from "./pages/Dashboard/Gallery/gallery";
// import DashboardGalleryAdd from "./pages/Dashboard/Gallery/galleryAdd";

const routers = [
  {
    name: "Index",
    url: "/",
    component: Discography,
    exact: true,
  },

  // {
  //   name: "Login",
  //   url: "/login",
  //   component: Login,
  //   exact: true,
  // },
  {
    name: "Discography",
    url: "/discography",
    component: Discography,
    exact: true,
  },
  {
    name: "Albums",
    url: "/albums",
    component: Discography,
    exact: true,
  },
  {
    name: "AlbumDetails",
    url: "/albums/:slug",
    component: AlbumDetails,
    exact: true,
  },
  {
    name: "Posts",
    url: "/posts",
    component: Posts,
    exact: true,
  },
  {
    name: "PostDetails",
    url: "/posts/:slug",
    component: PostDetails,
    exact: true,
  },
  {
    name: "CrewShow",
    url: "/crew/:slug",
    component: ParticipantShow,
    exact: true,
  },
  {
    name: "Crew",
    url: "/crew",
    component: Crew,
    exact: true,
  },
  {
    name: "TrackDetails",
    url: "/tracks/:slug",
    component: TrackDetails,
    exact: true,
  },
  // {
  //   name: "Dashboard",
  //   url: "/dashboard",
  //   component: Dashboard,
  //   exact: true,
  // },
  // {
  //   name: "DashboardLinks",
  //   url: "/dashboard/links",
  //   component: DashboardLinks,
  //   exact: true,
  // },
  // {
  //   name: "DashboardLinkCreate",
  //   url: "/dashboard/links/create",
  //   component: DashboardLinkEdit,
  //   exact: true,
  // },
  // {
  //   name: "DashboardLinkEdit",
  //   url: "/dashboard/links/:linkId/edit",
  //   component: DashboardLinkEdit,
  //   exact: true,
  // },
  // {
  //   name: "DashboardParticipants",
  //   url: "/dashboard/participants",
  //   component: DashboardParticipants,
  //   exact: true,
  // },
  // {
  //   name: "DashboardParticipant",
  //   url: "/dashboard/participants/:id",
  //   component: DashboardParticipantEdit,
  //   exact: true,
  // },
  // {
  //   name: "DashboardTags",
  //   url: "/dashboard/tags",
  //   component: DashboardTags,
  //   exact: true,
  // },
  // {
  //   name: "DashboardTag",
  //   url: "/dashboard/tags/:id",
  //   component: DashboardTagEdit,
  //   exact: true,
  // },
  // {
  //   name: "DashboardTracks",
  //   url: "/dashboard/tracks",
  //   component: DashboardTracks,
  //   exact: true,
  // },
  // {
  //   name: "DashboardTrack",
  //   url: "/dashboard/tracks/:trackId",
  //   component: DashboardTrack,
  //   exact: true,
  // },
  // {
  //   name: "DashboardPostEdit",
  //   url: "/dashboard/posts/:postId",
  //   component: DashboardPostEdit,
  //   exact: true,
  // },
  // {
  //   name: "DashboardPostCreate",
  //   url: "/dashboard/posts/create",
  //   component: DashboardPostEdit,
  //   exact: true,
  // },
  // {
  //   name: "DashboardGallery",
  //   url: "/dashboard/gallery",
  //   component: DashboardGallery,
  //   exact: true,
  // },
  // {
  //   name: "DashboardGalleryAdd",
  //   url: "/dashboard/gallery/add",
  //   component: DashboardGalleryAdd,
  //   exact: true,
  // },
];

export default routers;
