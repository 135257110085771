import { IAlbum } from "../../interfaces/common";
import { getAlbumCoverPath, getUrl } from "../../libs/helpers";
import { Link } from "react-router-dom";

type AlbumHeadProps = {
  album: IAlbum;
};

const AlbumHead = ({ album }: AlbumHeadProps) => {
  return (
    <div className="album-head">
      <div className="album-info__cover" draggable="true">
        <img
          draggable="false"
          loading="lazy"
          src={getAlbumCoverPath(album.cover)}
          alt={album.title}
        />
      </div>
      <div className="album-info__text">
        <span className="title" draggable="true" data-encore-id="text">
          <Link
            className="name"
            to={getUrl("AlbumDetails", { slug: album.slug })}
          >
            {album.title}
          </Link>
        </span>

        <div className="details">
          <span>{album.type}</span>
          <span className="year">{album.year}</span>
          <span className="count">
            {album.tracks &&
              album.tracks.length +
                (album.tracks.length > 1 ? " songs" : " song")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AlbumHead;
