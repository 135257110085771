import makeAction from "./../../libs/actions";

export const fetchAdminPosts = makeAction("admin/posts/FETCH_POSTS");
export const fetchDashboardPost = makeAction("admin/posts/FETCH_POST");
export const sendDashboardPost = makeAction("admin/posts/SEND_POST");
export const sendDashboardPostPublished = makeAction(
  "admin/posts/SET_POST_PUBLISHED"
);
export const attachDashboardFileToPost = makeAction(
  "admin/posts/ATTACH_FILE_TO_POST"
);
export const detachDashboardFileFromPost = makeAction(
  "admin/posts/DETACH_FILE_FROM_POST"
);
export const attachDashboardTagToPost = makeAction(
  "admin/posts/ATTACH_TAG_TO_POST"
);
export const detachDashboardTagFromPost = makeAction(
  "admin/posts/DETACH_TAG_FROM_POST"
);

export const setCoverFileToPost = makeAction("admin/posts/SET_COVER_TO_POST");
export const togglePublishedPost = makeAction(
  "admin/posts/TOGGLE_PUBLISHED_POST"
);
export const deleteCoverPost = makeAction("admin/posts/DELETE_COVER_TO_POST");

export const fetchDashboardParticipants = makeAction(
  "admin/posts/FETCH_PARTICIPANTS"
);
export const fetchDashboardParticipant = makeAction(
  "admin/posts/FETCH_PARTICIPANT"
);
export const sendDashboardParticipant = makeAction(
  "admin/posts/SEND_PARTICIPANT"
);
export const deleteDashboardParticipant = makeAction(
  "admin/posts/DELETE_PARTICIPANT"
);
export const attachDashboardFileToParticipant = makeAction(
  "admin/posts/ATTACH_FILE_TO_PARTICIPANT"
);
export const detachDashboardFileFromParticipant = makeAction(
  "admin/posts/DETACH_FILE_FROM_PARTICIPANT"
);
export const setAvatarFileToParticipant = makeAction(
  "admin/posts/SET_AVATAR_TO_PARTICIPANT"
);
export const deleteAvatar = makeAction("admin/posts/DELETE_AVATAR_PARTICIPANT");

export const fetchDashboardTags = makeAction("admin/tags/FETCH_DASHBOARD_TAGS");
export const sendDashboardTag = makeAction("admin/tags/SEND_DASHBOARD_TAG");
export const deleteDashboardTag = makeAction("admin/tags/DELETE_DASHBOARD_TAG");

export const fetchDashboardTracks = makeAction(
  "admin/tracks/FETCH_DASHBOARD_TRACKS"
);
export const sendDashboardTrack = makeAction(
  "admin/tracks/SEND_DASHBOARD_TRACK"
);

export const fetchAdminGallery = makeAction("admin/gallery/FETCH_GALLERY");
export const uploadAdminGalleryItem = makeAction(
  "admin/gallery/UPLOAD_GALLERY_ITEM"
);
export const deleteAdminGalleryItem = makeAction(
  "admin/gallery/DELETE_GALLERY_ITEM"
);

export const fetchDashboardLinks = makeAction("admin/links/FETCH_LINKS");
export const fetchDashboardLink = makeAction("admin/links/FETCH_ONE_LINK");
export const sendDashboardLink = makeAction("admin/links/SEND_LINKS");
export const deleteDashboardLink = makeAction("admin/links/DELETE_LINK");
export const moveDashboardLinkOrderUp = makeAction("admin/links/MOVE_LINK");
export const moveDashboardLinkOrderDown = makeAction("admin/links/MOVE_LINK");
