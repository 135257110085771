import { Reducer } from "redux";

import * as usersActions from "../../reducers/users/actions";
import { IAction } from "../../interfaces/action";

export const userReducers: Reducer = (
  state = {},
  { type, payload }: IAction
) => {
  switch (type) {
    case usersActions.getProfile.request: {
      return {};
    }
    case usersActions.getProfile.success: {
      return payload;
    }
    case usersActions.getProfile.failure: {
      return { ...state };
    }

    default:
      return state;
  }
};
