import React, { FunctionComponent, useEffect } from "react";
import { Dispatch } from "redux";

import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../reducers";
// import { useLocation } from "react-router-dom";

import { getAlbums } from "../../actions/albums";
import Loading from "../../components/loading";
import { IAlbum } from "../../interfaces/common";

import Album from "../../components/Album/album";

type DiscographyProps = {
  //   title: string;
  //   paragraph: string;
};

const Discography: FunctionComponent<DiscographyProps> = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { albums, isLoading } = useSelector((state: IState) => state.albums);

  useEffect(() => {
    dispatch(getAlbums());
  }, [dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className="album-section">
      {albums.map((album: IAlbum) => (
        <Album album={album} key={album.slug} />
      ))}
    </section>
  );
};

export default Discography;
