import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
// import Duration from './Duration';
import { IState } from "../../reducers";

import {
  LoopOffSvg,
  LoopOnSvg,
  MuteOnSvg,
  MuteOffSvg,
  PauseSvg,
  PlayNextSvg,
  PlayPrevSvg,
  PlaySvg,
} from "../Icons";
import "./player.scss";
import {
  usePlayTrack,
  usePlayPause,
  usePlayerOptions,
} from "../../hooks/usePlayer";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { ITrack } from "../../interfaces/common";

// import * as playerActions from "../../reducers/player/actions";

interface Props {}

const Player: React.FC<Props> = () => {
  const {
    track,
    playing,
    controls,
    light,
    volume,
    muted,
    loop,
    played,
    loaded,
    /*duration,*/ playbackRate,
    pip,
    seeking,
  } = useSelector((state: IState) => state.player);

  const [isOpenValueBar, setToggleOpenValueBar] = useState<boolean>(false);

  const valueBarRef = useRef(null);
  const valueBarWrapperRef = useRef(null);
  const player = useRef(null);

  useOutsideClick(valueBarWrapperRef, isOpenValueBar, setToggleOpenValueBar);

  const toggleOpenValueBar = () => setToggleOpenValueBar(!isOpenValueBar);

  const setPlayPauseButton = usePlayPause();
  const setPlayNextTrack = usePlayTrack();
  const setPlayerOptions = usePlayerOptions();

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!!track){
  //     dispatch({
  //       type: playerActions.setTrack.request,
  //       payload: { track },
  //     });
  //   }
  // }, []);

  const handlePlayPauseButton = (track: ITrack) => {
    setPlayPauseButton(track);
  };

  const handlePlayNextTrack = (op: string) => {
    setPlayNextTrack(op);
    setPlayerOptions({ playing: true });
  };

  const handleMutedOn = () => {
    setPlayerOptions({ muted: true, volume: 0 });
  };

  const handleMutedOff = () => {
    setPlayerOptions({ muted: false, volume: 1 });
  };

  const handleToggleLoop = () => {
    setPlayerOptions({ loop: !loop });
  };

  const handleVolumeChange = (e: any) => {
    setPlayerOptions({ volume: parseFloat(e.target.value), muted: false });
  };

  const handleSetPlaybackRate = (e: any) => {
    setPlayerOptions({ playbackRate: parseFloat(e.target.value) });
  };

  const handleTogglePIP = () => {
    setPlayerOptions({ pip: !pip });
  };

  // handlePlay = () => {
  //   this.props.playTrack();
  // };

  const handleEnablePIP = () => {
    setPlayerOptions({ pip: true });
  };

  const handleDisablePIP = () => {
    setPlayerOptions({ pip: false });
  };

  // handlePause = () => {
  //   console.log('onPause')
  //   this.setState({ playing: false })
  // }

  const handleSeekMouseDown = (e: any) => {
    setPlayerOptions({ seeking: true });
  };

  const handleSeekChange = (e: any) => {
    setPlayerOptions({ played: parseFloat(e.target.value) });
  };

  const handleSeekMouseUp = (e: any) => {
    setPlayerOptions({ seeking: false });
    if (!!player && player.current) {
      // @ts-ignore
      player.current.seekTo(parseFloat(e.target.value));
    }
  };

  const handleProgress = (state: any) => {
    if (!seeking) {
      setPlayerOptions(state);
    }
  };

  const handleEnded = () => {
    if (loop) {
      setPlayerOptions({ playing: loop });
    } else {
      setPlayNextTrack("next");
    }
  };

  const handleDuration = (duration: any) => {
    setPlayerOptions({ duration });
  };

  // const handleClickFullscreen = () => {
  //   // screenfull.request(findDOMNode(this.player))
  // };

  return (
    <div className="player-wrapper">
      <div className="loaded">
        <div className="loaded-bar" style={{ width: `${loaded * 100}%` }} />
      </div>

      <div className="played">
        <div className="played-bar" style={{ width: `${played * 100}%` }} />
      </div>

      <section className="section">
        <div
          className={`controlsBar ${!!track && track.src ? "" : "disabled"}`}
        >
          {loop ? (
            <LoopOnSvg width={20} height={20} onClick={handleToggleLoop} />
          ) : (
            <LoopOffSvg width={20} height={20} onClick={handleToggleLoop} />
          )}

          <div className="playPrev" onClick={() => handlePlayNextTrack("prev")}>
            <PlayPrevSvg />
          </div>

          {!!track && (
            <div
              className="playPause"
              onClick={() => handlePlayPauseButton(track)}
            >
              {playing ? <PauseSvg /> : <PlaySvg width={40} height={40} />}
            </div>
          )}

          <div className="playNext" onClick={() => handlePlayNextTrack("next")}>
            <PlayNextSvg />
          </div>

          <div className="value" ref={valueBarWrapperRef}>
            {isOpenValueBar && (
              <div className={`valueBarWrapper`}>
                <MuteOffSvg width={25} height={25} onClick={handleMutedOff} />
                <div className="valueBar">
                  <input
                    ref={valueBarRef}
                    type="range"
                    min={0}
                    max={1}
                    step="0.1"
                    value={volume}
                    onChange={handleVolumeChange}
                  />
                </div>
                <MuteOnSvg width={25} height={25} onClick={handleMutedOn} />
              </div>
            )}

            {muted ? (
              <MuteOnSvg onClick={toggleOpenValueBar} />
            ) : (
              <MuteOffSvg onClick={toggleOpenValueBar} />
            )}
          </div>
        </div>

        <div className="controlsSeek">
          <input
            type="range"
            min={0}
            max={1}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onTouchStart={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
            onTouchEnd={handleSeekMouseUp}
          />
        </div>
        {!!track && (
          <ReactPlayer
            ref={player}
            className="react-player"
            width="200px"
            height="100px"
            url={track.src}
            pip={pip}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            // onReady={() => console.log("onReady")}
            // onStart={() => console.log("onStart")}
            // onPlay={() => console.log("onPlay")}
            onEnablePIP={handleEnablePIP}
            onDisablePIP={handleDisablePIP}
            // onPause={() => console.log("onPause")}
            // onBuffer={() => console.log("onBuffer")}
            // onSeek={(e) => console.log("onSeek", e)}
            onEnded={handleEnded}
            onError={(e) => {
              console.log("onError", e);
              console.log(e);
            }}
            onProgress={handleProgress}
            onDuration={handleDuration}
          />
        )}

        {/*<table>*/}
        {/*<tbody>*/}
        {/*<tr>*/}
        {/*  <th>Controls</th>*/}
        {/*  <td>*/}
        {/*    /!*<button onClick={this.handleClickFullscreen}>Fullscreen</button>*!/*/}
        {/*    {light && <button onClick={() => this.player.showPreview()}>Show preview</button>}*/}
        {/*    {ReactPlayer.canEnablePIP(String(url)) && <button onClick={this.handleTogglePIP}>{pip ? 'Disable PiP' : 'Enable PiP'}</button>}*/}
        {/*  </td>*/}
        {/*</tr>*/}
        {/*<tr>*/}
        {/*  <th>Speed</th>*/}
        {/*  <td>*/}
        {/*    <button onClick={this.handleSetPlaybackRate} value={1}>1x</button>*/}
        {/*    <button onClick={this.handleSetPlaybackRate} value={1.5}>1.5x</button>*/}
        {/*    <button onClick={this.handleSetPlaybackRate} value={2}>2x</button>*/}
        {/*  </td>*/}
        {/*</tr>*/}

        {/*<tr>*/}
        {/*  <th>*/}
        {/*    <label htmlFor='controls'>Controls</label>*/}
        {/*  </th>*/}
        {/*<td>*/}
        {/*<input id='controls' type='checkbox' checked={controls} onChange={this.handleToggleControls} />*/}
        {/*<em>&nbsp; Requires player reload</em>*/}
        {/*</td>*/}
        {/*</tr>*/}

        {/*<tr>*/}
        {/*  <th>*/}
        {/*    <label htmlFor='light'>Light mode</label>*/}
        {/*  </th>*/}
        {/*  <td>*/}
        {/*  <input id='light' type='checkbox' checked={light} onChange={this.handleToggleLight} />*/}
        {/*  </td>*/}
        {/*</tr>*/}

        {/*  </tbody>*/}
        {/*</table>*/}
      </section>

      {/*<section className='section'>*/}
      {/*  <table>*/}
      {/*    <tbody>*/}
      {/*    <tr>*/}
      {/*      <th>playing</th>*/}
      {/*      <td>{playing ? 'true' : 'false'}</td>*/}
      {/*    </tr>*/}
      {/*    <tr>*/}
      {/*      <th>volume</th>*/}
      {/*      <td>{volume.toFixed(3)}</td>*/}
      {/*    </tr>*/}
      {/*    <tr>*/}
      {/*      <th>played</th>*/}
      {/*      <td>{played.toFixed(3)}</td>*/}
      {/*    </tr>*/}
      {/*    <tr>*/}
      {/*      <th>loaded</th>*/}
      {/*      <td>{loaded.toFixed(3)}</td>*/}
      {/*    </tr>*/}
      {/*    <tr>*/}
      {/*      <th>duration</th>*/}
      {/*      <td>*/}
      {/*        <Duration seconds={duration} />*/}
      {/*      </td>*/}
      {/*    </tr>*/}
      {/*    <tr>*/}
      {/*      <th>elapsed</th>*/}
      {/*      <td>*/}
      {/*        <Duration seconds={duration * played} />*/}
      {/*      </td>*/}
      {/*    </tr>*/}
      {/*    <tr>*/}
      {/*      <th>remaining</th>*/}
      {/*      <td>*/}
      {/*        <Duration seconds={duration * (1 - played)} />*/}
      {/*      </td>*/}
      {/*    </tr>*/}
      {/*    </tbody>*/}
      {/*  </table>*/}
      {/*</section>*/}
    </div>
  );
};

export default Player;
