// @ts-nocheck
import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import { getTracks } from "../../actions/tracks";
import { getProfile } from "../../actions/users";
import { getLinks } from "../../actions/links";
import { AnyAction, Dispatch } from "redux";

const Layout: React.FC = ({ children }: any) => {
  const dispatch: Dispatch<AnyAction> = useDispatch();

  useEffect(() => {
    dispatch(getTracks());
    dispatch(getProfile());
    dispatch(getLinks());
  }, [dispatch]);

  return <main>{children}</main>;
};

export default Layout;
