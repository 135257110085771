import { errorToast } from "../libs/helpers";
import * as usersActions from "../reducers/users/actions";
import { api_request } from "../api";

export const getProfile = (cb?: Function) => (dispatch: any) => {
  dispatch({ type: usersActions.getProfile.request });
  api_request
    .getProfile()
    .then((response) => {
      dispatch({
        type: usersActions.getProfile.success,
        payload: response.data,
      });
      if (cb) {
        cb(response.data);
      }
    })
    .catch((error) => {
      errorToast(error);
      dispatch({ type: usersActions.getProfile.failure, payload: error });
    });
};
