import { useSelector } from "react-redux";
import { IState } from "../../reducers";

import { IAlbum, ITrack } from "../../interfaces/common";
import { formatTime, getUrl } from "../../libs/helpers";
import { Link } from "react-router-dom";
import { usePlayPause } from "../../hooks/usePlayer";
import AlbumHead from "./albumHead";

type AlbumProps = {
  album: IAlbum;
};

const Album = ({ album }: AlbumProps) => {
  const { track: currentTrack, playing: isPlaying } = useSelector(
    (state: IState) => state.player
  );

  const setTrack = usePlayPause();

  const playTrack = (track: ITrack) => {
    setTrack(track);
  };

  return (
    <>
      <AlbumHead album={album} />

      {album?.tracks?.length > 0 ? (
        <div className="track-list">
          <div className="track-list__inner">
            {album.tracks.map((track: ITrack, trackIndex: number) => {
              return (
                <div className="row" role="row" key={track.slug}>
                  <div
                    className={`tracklist-row ${
                      track.slug === currentTrack?.slug ? "playing" : ""
                    }`}
                    draggable="true"
                    role="presentation"
                  >
                    <div
                      className="gridcell index"
                      role="gridcell"
                      onClick={() => playTrack(track)}
                    >
                      {!!currentTrack && track.slug === currentTrack.slug ? (
                        isPlaying ? (
                          <button style={{ border: 0 }}>
                            <svg
                              width={17}
                              height={17}
                              data-encore-id="icon"
                              role="img"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5.7 3a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7H5.7zm10 0a.7.7 0 0 0-.7.7v16.6a.7.7 0 0 0 .7.7h2.6a.7.7 0 0 0 .7-.7V3.7a.7.7 0 0 0-.7-.7h-2.6z"></path>
                            </svg>
                          </button>
                        ) : (
                          <button style={{ border: 0 }}>
                            <svg
                              width={17}
                              height={17}
                              role="img"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                            >
                              <path d="m7.05 3.606 13.49 7.788a.7.7 0 0 1 0 1.212L7.05 20.394A.7.7 0 0 1 6 19.788V4.212a.7.7 0 0 1 1.05-.606z"></path>
                            </svg>
                          </button>
                        )
                      ) : (
                        trackIndex + 1
                      )}
                    </div>
                    <div className="gridcell song" role="gridcell">
                      <div className="song__inner">
                        <Link
                          draggable="false"
                          className="name"
                          to={getUrl("TrackDetails", {
                            slug: track.slug,
                          })}
                        >
                          {track.title}
                        </Link>

                        <Link
                          to={getUrl("AlbumDetails", { slug: album.slug })}
                          className="album"
                          draggable="true"
                          dir="auto"
                        >
                          {album.title}
                        </Link>
                      </div>
                    </div>
                    <div className="gridcell last" role="gridcell">
                      <div className="time">{formatTime(track.duration)}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Album;
