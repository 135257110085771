// @ts-nocheck
import * as tracksActions from "../reducers/tracks/actions";
import * as playerActions from "../reducers/player/actions";
import axiosInstance from "../libs/axios";
import { errorToast } from "../libs/helpers";
import { ITrack } from "../reducers/tracks/reducer";
import { Action } from "redux";
import { getTrackUrlByFalename } from "../libs/helpers";

export const getTracks = (): Action => (dispatch: any) => {
  dispatch({ type: tracksActions.fetchTracksAction.request });
  axiosInstance
    .get(`${process.env.REACT_APP_API_URL}/api/v1/tracks`)
    .then((response) => {
      dispatch({
        type: tracksActions.fetchTracksAction.success,
        payload: response.data,
      });
      return response.data;
    })
    .then((tracks: ITrack[]) => {
      if (tracks.length > 0) {
        // set 0 track if exist
        dispatch({
          type: playerActions.setTrack.request,
          payload: {
            track: { ...tracks[0], src: getTrackUrlByFalename(tracks[0].src) },
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: tracksActions.fetchTracksAction.failure,
        payload: error,
      });
    });
};

export const getTrackBySlug =
  ({ slug }: { slug: string }) =>
  (dispatch: any) => {
    dispatch({ type: tracksActions.fetchTrackBySlugAction.request });
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}/api/v1/tracks/${slug}`)
      .then((response) => {
        dispatch({
          type: tracksActions.fetchTrackBySlugAction.success,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorToast(error);
        dispatch({
          type: tracksActions.fetchTrackBySlugAction.failure,
          payload: error,
        });
      });
  };
