import { Reducer } from "redux";

import * as participantsActions from "../../reducers/participants/actions";
import { IAction } from "../../interfaces/action";
import { shuffle } from "../../libs/helpers";
import { IParticipant } from "../../interfaces/common";

export interface IParticipantsState {
  participants: IParticipant[];
  isLoading: boolean;
}

export const participantInitialState: IParticipantsState = {
  participants: [],
  isLoading: false,
};

export const participantsReducers: Reducer = (
  state = participantInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    case participantsActions.fetchParticipantsAction.request: {
      return { ...state, isLoading: true };
    }
    case participantsActions.fetchParticipantsAction.success: {
      const participants = payload;
      return {
        ...state,
        participants: shuffle(participants),
        isLoading: false,
      };
    }
    case participantsActions.fetchParticipantsAction.failure: {
      return { ...state, isLoading: false };
    }

    case participantsActions.fetchParticipantBySlugAction.request: {
      return { ...state, isLoading: true };
    }
    case participantsActions.fetchParticipantBySlugAction.success: {
      const participants = [payload];
      return { ...state, participants: participants, isLoading: false };
    }
    case participantsActions.fetchParticipantBySlugAction.failure: {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};
