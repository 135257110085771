import { Reducer } from "redux";

import * as tracksActions from "../../reducers/tracks/actions";
import { IAction } from "../../interfaces/action";
import { acceptChangedItemToList } from "../../libs/reducers";
import { ITrack } from "../../interfaces/common";
import { getTrackUrlByFalename } from "../../libs/helpers";

export interface ITrackState {
  tracks: ITrack[];
  isLoading: boolean;
  meta: {
    total: number;
    current: number;
  };
}

export const trackInitialState: ITrackState = {
  tracks: [],
  isLoading: false,
  meta: {
    total: 0,
    current: 1,
  },
};

export const tracksReducers: Reducer = (
  state = trackInitialState,
  { type, payload }: IAction
) => {
  switch (type) {
    case tracksActions.fetchTracksAction.request: {
      return { ...state, tracks: state.tracks, isLoading: false };
    }
    case tracksActions.fetchTracksAction.success: {
      return {
        ...state,
        tracks: payload.map((track: ITrack) => {
          return { ...track, src: getTrackUrlByFalename(track.src) };
        }),
        isLoading: false,
      };
    }
    case tracksActions.fetchTracksAction.failure: {
      return { ...state, tracks: state.tracks, isLoading: false };
    }

    case tracksActions.fetchTrackBySlugAction.request: {
      return { ...state, tracks: state.tracks, isLoading: true };
    }
    case tracksActions.fetchTrackBySlugAction.success: {
      // return { ...state, tracks: acceptChangedItemToList(state.tracks, payload), isLoading: false };
      const track = { ...payload, src: getTrackUrlByFalename(payload.src) };
      return {
        ...state,
        tracks: acceptChangedItemToList(state.tracks, track),
        isLoading: false,
      };
    }
    case tracksActions.fetchTrackBySlugAction.failure: {
      return { ...state, tracks: state.tracks, isLoading: false };
    }

    default:
      return state;
  }
};
