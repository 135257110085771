import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

export const useStateWithLocalStorage = (
  LSKey: string
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [value, setValue] = useState(
    ["1", "0"].includes(String(localStorage.getItem(LSKey)))
      ? localStorage.getItem(LSKey) === "1"
      : false
  );

  useEffect(() => {
    localStorage.setItem(LSKey, value ? "1" : "0");
  }, [value]);

  return [value, setValue];
};

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
};
