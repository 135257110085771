import * as albumsActions from "../reducers/albums/actions";
import { errorToast } from "../libs/helpers";
import { api_request } from "../api";
import { Dispatch } from "redux";

interface GetAlbumBySlugArgs {
  slug: string;
}

export const getAlbums = () => (dispatch: Dispatch) => {
  dispatch({ type: albumsActions.fetchAlbumsAction.request });
  api_request
    .getAlbums()
    .then((response) => {
      dispatch({
        type: albumsActions.fetchAlbumsAction.success,
        payload: response.data,
      });
    })
    .catch((error) => {
      errorToast(error);
      dispatch({
        type: albumsActions.fetchAlbumsAction.failure,
        payload: error,
      });
    });
};

export const getAlbumBySlug =
  ({ slug }: GetAlbumBySlugArgs) =>
  (dispatch: Dispatch) => {
    dispatch({ type: albumsActions.fetchAlbumBySlugAction.request });
    api_request
      .getPostBySlug({ slug })
      .then((response) => {
        dispatch({
          type: albumsActions.fetchAlbumBySlugAction.success,
          payload: response.data,
        });
      })
      .catch((error) => {
        errorToast(error);
        dispatch({
          type: albumsActions.fetchAlbumBySlugAction.failure,
          payload: error,
        });
      });
  };
