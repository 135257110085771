// import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITrack } from "../interfaces/common";
import { IState } from "../reducers";
import * as playerActions from "../reducers/player/actions";
import { IPlayerState } from "../reducers/player/reducer";

export type PlayerOptions = { [P in keyof IPlayerState]?: IPlayerState[P] };

export const usePlayPause = () => {
  const dispatch = useDispatch();
  const { track, playing } = useSelector((state: IState) => state.player);

  return (trackToPlay: ITrack) => {
    // if no playing track selected at all -> set new track
    // or
    // playing track is not equal to secelected -> set new track
    if (!track || (track && trackToPlay.slug !== track.slug)) {
      dispatch({
        type: playerActions.setTrack.request,
        payload: { track: trackToPlay },
      });
      dispatch({ type: playerActions.playAction.request });
    } else {
      // PLAY PAUSE SWITCHER
      if (trackToPlay.slug === track?.slug) {
        dispatch(
          playing
            ? { type: playerActions.pauseAction.request }
            : { type: playerActions.playAction.request }
        );
      }
    }
  };
};

export const usePlayTrack = () => {
  const dispatch = useDispatch();
  const { track } = useSelector((state: IState) => state.player);
  const tracks = useSelector((state: IState) => state.tracks.tracks);

  return (op = "next") => {
    if (!tracks.length) {
      return;
    }
    // if no track set
    if (!track) {
      const trackIndex = op === "prev" ? tracks.length - 1 : 0;
      dispatch({
        type: playerActions.setTrack.request,
        payload: { track: tracks[trackIndex] },
      });
    } else {
      if (op === "prev") {
        const trackIndex = tracks.findIndex((t) => t.slug === track.slug);
        dispatch({
          type: playerActions.setTrack.request,
          payload: {
            track: tracks[trackIndex > 0 ? trackIndex - 1 : tracks.length - 1],
          },
        });
      } else if (op === "next") {
        const trackIndex = tracks.findIndex((t) => t.slug === track.slug);
        dispatch({
          type: playerActions.setTrack.request,
          payload: { track: tracks[(trackIndex + 1) % tracks.length] },
        });
      }
    }
  };
};

export const usePlayerOptions = () => {
  const {
    track,
    playing,
    controls,
    light,
    volume,
    muted,
    loop,
    played,
    loaded,
    /*duration,*/ playbackRate,
    pip,
    seeking,
  } = useSelector((state: IState) => state.player);
  const dispatch = useDispatch();

  const changePlayerOptions = (option: PlayerOptions) =>
    dispatch({
      type: playerActions.setPlayerOptionsAction.request,
      payload: option,
    });

  return (option: PlayerOptions) => {
    return changePlayerOptions(option);
  };
};
