import YoutubeReactPlayer from "react-player/youtube";
import FacebookReactPlayer from "react-player/facebook";
import MixcloudReactPlayer from "react-player/mixcloud";

import thumbnailVideo from "../../static/thumbnail-video.png";
import {
  facebookVideoRegex,
  youTubeVideoRegex,
  mixcloudVideoRegex,
} from "../../libs/helpers";
import { IPost } from "../../interfaces/common";

type MediaPlayerProps = {
  post: IPost;
};

const MediaPlayer = ({ post }: MediaPlayerProps) => {
  if (!post.media) {
    return null;
  }

  let urls = [];
  if (Array.isArray(post.media)) {
    urls = post.media;
  } else if (typeof post.media === "string") {
    urls.push(post.media);
  } else {
    return null;
  }

  return (
    <div className={`media ${urls.length > 1 ? "multi" : ""}`}>
      {urls.map((url: string) => {
        const isYouTubeVideo: boolean = !!`${url}`.match(youTubeVideoRegex);
        const isFacebookVideo: boolean = !!`${url}`.match(facebookVideoRegex);
        const isMixcloudVideo: boolean = !!`${url}`.match(mixcloudVideoRegex);
        if (!url || !(isYouTubeVideo || isFacebookVideo || isMixcloudVideo)) {
          return null;
        }
        return (
          <div
            key={url}
            className={`${isYouTubeVideo ? "iframe-video-wrapper" : ""}`}
            style={
              isYouTubeVideo || isFacebookVideo
                ? {
                    backgroundImage: `url(${thumbnailVideo})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }
                : {}
            }
          >
            {isYouTubeVideo && (
              <YoutubeReactPlayer
                url={url}
                controls
                width={"100%"}
                height={"100%"}
              />
            )}
            {isFacebookVideo && (
              <FacebookReactPlayer
                url={url}
                controls
                width={"100%"}
                height={"100%"}
              />
            )}
            {isMixcloudVideo && (
              <MixcloudReactPlayer
                url={url}
                controls
                config={{
                  options: {
                    error: () => {},
                    light: false,
                    disableHotkeys: true,
                  },
                }}
                width={"100%"}
                height={"100%"}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MediaPlayer;
