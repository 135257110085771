import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IState } from "../../reducers";
import { getPostBySlug } from "../../actions/posts";
import { Link, useParams } from "react-router-dom";
import "./postStyles.scss";

import { getImagePath, getUrl } from "../../libs/helpers";
import { Helmet } from "react-helmet";
import Loading from "../../components/loading";
import { Dispatch } from "redux";
import { IPost } from "../../interfaces/common";
import Post from "../../components/Posts/post";

const PostDetails: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [post, setPost] = useState<IPost>();
  const { slug } = useParams();

  const { posts, isLoading } = useSelector((state: IState) => state.posts);

  useEffect(() => {
    const tPost: IPost | undefined = posts?.find((p) => p.slug === slug);
    if (tPost) {
      setPost(tPost);
    }
  }, [posts]);

  useEffect(() => {
    if (slug) {
      dispatch(getPostBySlug({ slug }));
    }
  }, [dispatch, slug]);

  const images: string[] =
    post?.files && post.files.length > 0
      ? post.files.map((file: any) => file.filename)
      : [];

  if (post?.cover) {
    images.unshift(post.cover.filename);
  }

  return (
    <section className="postDetails">
      {isLoading ? (
        <Loading />
      ) : post ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{post.title} | Komilfo </title>
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_HOST_URL}/posts/${post.slug}`}
          />
          <meta name="description" content="Komilfo band official" />
          {/* OpenGraph tags */}
          <meta
            name="og:url"
            content={`${process.env.REACT_APP_HOST_URL}/posts/${post.slug}`}
          />
          <meta name="og:title" content={`${post.title} | Komilfo`} />
          <meta name="og:description" content={post.title || ""} />
          {post?.cover && (
            <meta name="og:image" content={getImagePath(post.cover.filename)} />
          )}
          <meta name="og:type" content="website" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content={`${post.title} | Komilfo`} />
          <meta name="twitter:description" content={post.title} />
          {post?.cover && (
            <meta
              name="twitter:image"
              content={getImagePath(post.cover.filename)}
            />
          )}
          <meta name="twitter:card" content="summary" />
        </Helmet>
      ) : (
        <h4>this page is not found</h4>
      )}

      {!!post && (
        <div className="breadcrumbs">
          <Link to={getUrl("Posts")}>Блог</Link> &gt;&gt;{" "}
          <Link to={getUrl("PostDetails", { slug: post.slug })}>
            {post.title}
          </Link>
        </div>
      )}
      <div className="post">
        <Post post={post} />
      </div>
    </section>
  );
};

export default PostDetails;
