// @ts-nocheck
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getParticipants } from "../../actions/participants";
import { IState } from "../../reducers";

import "./crewStyles.scss";
import komilfobandImg from "../../static/komilfoband.jpg";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import { getImageThumbPath } from "../../libs/helpers";
import { IParticipant } from "../../interfaces/common";

import { getUrl } from "../../libs/helpers";

const Crew = () => {
  const dispatch = useDispatch();
  const { participants, isLoading } = useSelector(
    (state: IState) => state.participants
  );

  useEffect(() => {
    dispatch(getParticipants());
  }, [dispatch]);

  return (
    <>
      <section className="about">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Про гурт | Komilfo </title>
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_HOST_URL}/crew`}
          />
          <meta name="description" content="Komilfo band official" />
          {/* OpenGraph tags */}
          <meta
            name="og:url"
            content={`${process.env.REACT_APP_HOST_URL}/crew`}
          />
          <meta name="og:title" content="Про гурт | Komilfo" />
          <meta name="og:description" content="Про гурт | Komilfo" />
          <meta name="og:image" content={komilfobandImg} />
          <meta name="og:type" content="website" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Про гурт | Komilfo" />
          <meta name="twitter:description" content="Про гурт | Komilfo" />
          <meta name="twitter:image" content={komilfobandImg} />
          <meta name="twitter:card" content="summary" />
        </Helmet>

        <h2>Про Гурт</h2>
        <p>
          Гурт Komilfo існує більше 10 років. Старий склад зразка 2007 року,
          числені концерти, двічі виграна Червона Рута в Сумах, недописаний
          альбом лишилися в минулому.{" "}
        </p>
        <p>
          У нинішньому складі гурт зібрався в листопаді 2015 року у складі
          Віктора Мірошникова, що полишив на той час гурт ДК Денс, В'ячеслава
          Грабчака та Віталія Тітаренка.
        </p>
        <p>
          Згодом до гурту приєдналися барабанщик Максим Машенцов та вокалістка
          Юлія Новикова.
        </p>
        <p>
          У різний час учасниками гурту були Любов Таран, Олександр Головченко
          та Ярослав Данченко.
        </p>

        <h2>Учасники</h2>
        <div className="participants">
          {participants.map((participant: IParticipant) => (
            <Link
              key={participant.slug}
              className="participant"
              to={getUrl("CrewShow", { slug: participant.slug })}
            >
              {participant?.cover && participant?.cover?.length > 0 && (
                <div
                  className="participant_avatar"
                  style={{
                    backgroundImage: `url(${getImageThumbPath(
                      participant?.cover[0].filename
                    )})`,
                  }}
                />
              )}
              <div className="participant_info">
                <h3>
                  {participant.firstname} {participant.lastname}
                </h3>
                <p className="bio">{participant.bio}</p>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Crew;
