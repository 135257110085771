import React from "react";
import { useSelector } from "react-redux";
import { IState } from "../../reducers";

import "./trackListStyles.scss";

// icons
import {
  PauseSvg,
  PlaySvg,
  DragUpSvg,
  DragDownSvg,
  TextIconSvg,
} from "../../components/Icons";
import { getUrl, toMMSS } from "../../libs/helpers";
import { Link } from "react-router-dom";
import { ITrack } from "../../interfaces/common";
import { usePlayPause } from "../../hooks/usePlayer";

interface TracksProps {
  togglePlayer: Function;
  isPlayerOpen: boolean;
}

const Tracks: React.FC<TracksProps> = ({ togglePlayer, isPlayerOpen }) => {
  const tracks = useSelector((state: IState) => state.tracks.tracks);
  const {
    track: currentTrack,
    playing: isPlaying,
    playedSeconds,
    loaded,
  } = useSelector((state: IState) => state.player);

  const toggleTrackListOpen = () => {
    togglePlayer();
  };

  const onClickTrackText = () => {
    if (window.innerWidth < 801) {
      togglePlayer();
    }
  };

  const setTrack = usePlayPause();

  const playTrack = (track: ITrack) => {
    setTrack(track);
  };

  return (
    <div className="trackList">
      <div className="toggle-btn" onClick={toggleTrackListOpen}>
        {isPlayerOpen ? (
          <DragUpSvg width={20} height={20} />
        ) : (
          <DragDownSvg width={20} height={20} />
        )}
      </div>

      <div className="title" onClick={toggleTrackListOpen}>
        <span className="mobile">
          {!!currentTrack &&
            `[${toMMSS(Math.floor(playedSeconds))}] Комільфо - ${
              currentTrack.title
            } ${loaded < 1 ? `..${Math.floor(loaded * 100)}%` : ""}`}
        </span>
      </div>
      <div className="tracksPlayList">
        {tracks.map((track: ITrack) => {
          const isCurrent = !!currentTrack && track.slug === currentTrack.slug;
          return (
            <div
              className={`track ${isCurrent ? "current" : ""}`}
              key={track.slug}
            >
              <div className="trackButton" onClick={() => playTrack(track)}>
                {!!currentTrack &&
                track.slug === currentTrack.slug &&
                isPlaying ? (
                  <PauseSvg />
                ) : (
                  <PlaySvg />
                )}
              </div>

              <div className="trackTitle" onClick={() => playTrack(track)}>
                <h3>{track.title}</h3>
                {track?.album && <p>{track.album.title}</p>}
              </div>

              <Link
                className="trackLink"
                to={getUrl("TrackDetails", { slug: track.slug })}
                onClick={onClickTrackText}
              >
                <TextIconSvg />
                <span>info</span>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tracks;
